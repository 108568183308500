import React from 'react';
import { Search, Code, LineChart } from 'lucide-react';

const steps = [
  {
    icon: Search,
    title: 'Discovery & Design',
    description: 'Engage stakeholders to align policy goals with operational needs, creating detailed roadmaps.',
  },
  {
    icon: Code,
    title: 'Implementation',
    description: 'Develop secure, scalable systems using cloud-native technologies and modular architectures.',
  },
  {
    icon: LineChart,
    title: 'Optimisation',
    description: 'Monitor and refine systems post-launch for continuous improvement and adaptability.',
  },
];

export function ApproachSection() {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl font-bold text-center mb-16">Our Approach</h2>
          
          <div className="relative">
            {/* Connection Line */}
            <div className="absolute left-1/2 top-0 bottom-0 w-0.5 bg-blue-100 hidden md:block" />
            
            {/* Steps */}
            <div className="space-y-12">
              {steps.map((step, index) => {
                const Icon = step.icon;
                return (
                  <div 
                    key={step.title}
                    className="relative flex items-center"
                  >
                    {/* Step Content */}
                    <div className="bg-white p-6 rounded-xl shadow-sm w-full md:w-[calc(50%-2rem)] 
                                  md:ml-auto md:even:ml-0 md:even:mr-auto">
                      <div className="flex items-center mb-4">
                        <div className="p-2 bg-blue-50 rounded-lg mr-4">
                          <Icon className="w-6 h-6 text-blue-600" />
                        </div>
                        <h3 className="text-xl font-semibold">{step.title}</h3>
                      </div>
                      <p className="text-gray-600">{step.description}</p>
                    </div>
                    
                    {/* Step Number */}
                    <div className="absolute left-1/2 -translate-x-1/2 w-8 h-8 rounded-full bg-blue-600 
                                  text-white flex items-center justify-center font-bold hidden md:flex">
                      {index + 1}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}