import { Brain, Shield, Radio, Code, Award, Network } from 'lucide-react';

export const techData = [
  {
    title: 'AI & Machine Learning',
    description: 'We harness the power of artificial intelligence to transform critical industries.',
    icon: Brain,
    items: [
      {
        name: 'Multi-Agent AI Framework',
        description: 'Adaptive, decentralized AI systems for complex environments'
      },
      {
        name: 'Natural Language Processing',
        description: 'Advanced language understanding and generation capabilities'
      },
      {
        name: 'Predictive Analytics',
        description: 'Data-driven insights for proactive decision making'
      }
    ]
  },
  {
    title: 'Infrastructure',
    description: 'Our robust infrastructure solutions ensure reliability and efficiency.',
    icon: Radio,
    items: [
      {
        name: 'Distributed Acoustic Sensing',
        description: 'Real-time monitoring using fiber optic networks'
      },
      {
        name: 'Edge Computing',
        description: 'Intelligent data processing at the source'
      },
      {
        name: 'Cloud Infrastructure',
        description: 'Scalable, secure platforms for seamless integration'
      }
    ]
  },
  {
    title: 'Security & Compliance',
    description: 'We adhere to the highest standards of security and compliance.',
    icon: Shield,
    items: [
      {
        name: 'ISO 27001 Aligned',
        description: 'Comprehensive information security management'
      },
      {
        name: 'GDPR Compliant',
        description: 'Ensuring data privacy and protection'
      },
      {
        name: 'Cyber Essentials Certified',
        description: 'Secure infrastructure against cyber threats'
      }
    ]
  },
  {
    title: 'Development',
    description: 'Modern development practices and tools for reliable solutions.',
    icon: Code,
    items: [
      {
        name: 'Agile Methodology',
        description: 'Flexible, iterative development approach'
      },
      {
        name: 'CI/CD Pipeline',
        description: 'Automated testing and deployment'
      },
      {
        name: 'Version Control',
        description: 'Secure code management and collaboration'
      }
    ]
  },
  {
    title: 'Innovation',
    description: 'Recognized leader in technological innovation.',
    icon: Award,
    items: [
      {
        name: 'Innovate UK Accreditation',
        description: 'Acknowledged for pioneering advancements'
      },
      {
        name: 'Research Partnerships',
        description: 'Collaboration with leading institutions'
      },
      {
        name: 'Patent Portfolio',
        description: 'Protected intellectual property'
      }
    ]
  },
  {
    title: 'Integration',
    description: 'Seamless integration capabilities across platforms.',
    icon: Network,
    items: [
      {
        name: 'API Development',
        description: 'RESTful and GraphQL interfaces'
      },
      {
        name: 'System Integration',
        description: 'Connect diverse technology ecosystems'
      },
      {
        name: 'Data Exchange',
        description: 'Secure, efficient data transfer protocols'
      }
    ]
  }
];