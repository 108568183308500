import React from 'react';

export function LoadingSpinner() {
  return (
    <div 
      className="inline-flex items-center justify-center"
      aria-hidden="true"
    >
      <div className="w-12 h-12 border-4 border-blue-200 border-t-blue-600 rounded-full animate-spin" />
    </div>
  );
}