import React from 'react';
import { LucideIcon } from 'lucide-react';

interface NavigationCardProps {
  title: string;
  description: string;
  icon: LucideIcon;
  href: string;
}

export function NavigationCard({ title, description, icon: Icon, href }: NavigationCardProps) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="block p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300"
      role="link"
      tabIndex={0}
      aria-label={`${title} - ${description}`}
    >
      <div className="flex items-start space-x-4">
        <div className="flex-shrink-0">
          <Icon className="w-6 h-6 text-blue-600" aria-hidden="true" />
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">{title}</h3>
          <p className="text-gray-600">{description}</p>
        </div>
      </div>
    </a>
  );
}