import React from 'react';
import { QRCode } from './QRCode';

export function QRCodeSection() {
  return (
    <div className="text-center">
      <QRCode url="https://proaptus.co.uk/chinedu-achara" />
      <p className="text-gray-600 mt-4">Scan to Save My Details</p>
    </div>
  );
}