import React from 'react';
import { Link } from '../../layout/Link';
import { ArrowRight } from 'lucide-react';

export function TechCTA() {
  return (
    <div className="text-center mt-12">
      <Link 
        href="/contact"
        className="inline-flex items-center text-blue-600 hover:text-blue-700"
      >
        Discover how our technology ecosystem can transform your operations
        <ArrowRight className="ml-2 h-5 w-5" />
      </Link>
    </div>
  );
}