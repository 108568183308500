import React from 'react';
import { TechCategories } from './technology/TechCategories';
import { TechHeader } from './technology/TechHeader';
import { TechCTA } from './technology/TechCTA';

export function TechnologyEcosystem() {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <TechHeader />
        <TechCategories />
        <TechCTA />
      </div>
    </section>
  );
}