import React from 'react';
import { LucideIcon } from 'lucide-react';

interface TechItem {
  name: string;
  description: string;
}

interface TechCategoryProps {
  title: string;
  description: string;
  items: TechItem[];
  icon: LucideIcon;
}

export function TechCategory({ title, description, items, icon: Icon }: TechCategoryProps) {
  return (
    <div className="bg-gray-50 p-6 rounded-xl">
      <div className="flex items-center mb-4">
        <div className="p-2 bg-blue-50 rounded-lg mr-4">
          <Icon className="w-6 h-6 text-blue-600" />
        </div>
        <h3 className="text-xl font-semibold">{title}</h3>
      </div>
      <p className="text-gray-600 mb-6">{description}</p>
      <ul className="space-y-3">
        {items.map((item) => (
          <li key={item.name} className="flex items-start">
            <span className="w-2 h-2 mt-2 rounded-full bg-blue-600 mr-3" />
            <div>
              <span className="font-medium">{item.name}</span>
              <p className="text-sm text-gray-600">{item.description}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}