import { useState, useEffect } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { GOOGLE_MAPS_API_KEY } from '../components/contact/map/GoogleMapConfig';

export function useGoogleMapsLoader() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [loadError, setLoadError] = useState<Error | null>(null);

  useEffect(() => {
    const loader = new Loader({
      apiKey: GOOGLE_MAPS_API_KEY,
      version: 'weekly',
      libraries: ['places']
    });

    loader.load()
      .then(() => {
        setIsLoaded(true);
        setLoadError(null);
      })
      .catch((err) => {
        console.error('Error loading Google Maps:', err);
        setLoadError(err);
      });
  }, []);

  return { isLoaded, loadError };
}