import React from 'react';
import { Accessibility, Users, HeadphonesIcon, Building2 } from 'lucide-react';

const audiences = [
  {
    icon: Accessibility,
    title: 'Disabled Travelers',
    description: 'Tailored features that prioritize accessibility and independence throughout the journey.',
  },
  {
    icon: Users,
    title: 'General Travelers',
    description: 'Personalized planning and real-time updates for hassle-free travel experiences.',
  },
  {
    icon: HeadphonesIcon,
    title: 'Transport Operators',
    description: 'Improved customer service by bridging communication gaps with travelers.',
  },
  {
    icon: Building2,
    title: 'Urban Planners',
    description: 'Actionable data for infrastructure accessibility improvements and optimization.',
  },
];

export function TargetAudience() {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-16">Who Benefits</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {audiences.map((audience) => {
            const Icon = audience.icon;
            return (
              <div key={audience.title} className="bg-gray-50 p-6 rounded-lg">
                <Icon className="w-8 h-8 text-blue-600 mb-4" />
                <h3 className="text-lg font-semibold mb-3">{audience.title}</h3>
                <p className="text-gray-600">{audience.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}