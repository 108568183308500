import { useEffect } from 'react';

export function useScrollRestoration() {
  useEffect(() => {
    const handleNavigation = (e?: PopStateEvent) => {
      // Only scroll to top on navigation events, not regular scrolling
      if (e || window.location.hash === '') {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'auto' // Changed from 'smooth' to prevent interference
        });
      }
    };

    // Handle browser back/forward navigation
    window.addEventListener('popstate', handleNavigation);
    
    // Handle click navigation, but only for non-hash links
    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const link = target.closest('a');
      
      if (link && 
          link.href && 
          link.origin === window.location.origin && 
          !link.hasAttribute('download') && 
          !link.hasAttribute('target') &&
          !link.hash) { // Only scroll for non-hash links
        handleNavigation();
      }
    };

    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('popstate', handleNavigation);
      window.removeEventListener('click', handleClick);
    };
  }, []);
}