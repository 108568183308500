import React from 'react';
import { MapPin, Mail, Phone, Linkedin } from 'lucide-react';
import { AnimateOnScroll } from '../common/animations/AnimateOnScroll';

export function ContactInfo() {
  return (
    <AnimateOnScroll animation="animate-slide-up" delay={200}>
      <div className="bg-white p-8 rounded-xl shadow-sm">
        <h2 className="text-2xl font-bold mb-8">Our Contact Information</h2>
        
        <div className="space-y-6">
          <div className="flex items-start space-x-4 group">
            <div className="p-2 bg-blue-50 rounded-lg group-hover:bg-blue-100 transition-colors">
              <MapPin className="w-6 h-6 text-blue-600" />
            </div>
            <div>
              <h3 className="font-semibold mb-2">Registered Address</h3>
              <p className="text-gray-600">
                1 Derwent Business Centre<br />
                Clarke Street<br />
                Derby, DE1 2BU
              </p>
            </div>
          </div>

          <div className="flex items-start space-x-4 group">
            <div className="p-2 bg-blue-50 rounded-lg group-hover:bg-blue-100 transition-colors">
              <Mail className="w-6 h-6 text-blue-600" />
            </div>
            <div>
              <h3 className="font-semibold mb-2">Email</h3>
              <a 
                href="mailto:contact@proaptus.co.uk" 
                className="text-blue-600 hover:text-blue-700 transition-colors"
              >
                contact@proaptus.co.uk
              </a>
            </div>
          </div>

          <div className="flex items-start space-x-4 group">
            <div className="p-2 bg-blue-50 rounded-lg group-hover:bg-blue-100 transition-colors">
              <Phone className="w-6 h-6 text-blue-600" />
            </div>
            <div>
              <h3 className="font-semibold mb-2">Phone</h3>
              <a 
                href="tel:+441732495967" 
                className="text-blue-600 hover:text-blue-700 transition-colors"
              >
                01732 495967
              </a>
            </div>
          </div>

          <div className="flex items-start space-x-4 group">
            <div className="p-2 bg-blue-50 rounded-lg group-hover:bg-blue-100 transition-colors">
              <Linkedin className="w-6 h-6 text-blue-600" />
            </div>
            <div>
              <h3 className="font-semibold mb-2">Connect with Us</h3>
              <a 
                href="https://www.linkedin.com/company/proaptus/"
                className="text-blue-600 hover:text-blue-700 transition-colors inline-flex items-center space-x-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                Follow us on LinkedIn
              </a>
            </div>
          </div>
        </div>
      </div>
    </AnimateOnScroll>
  );
}