import React from 'react';
import { Shield } from 'lucide-react';

export function PrivacyHeader() {
  return (
    <div className="p-8 border-b">
      <div className="flex items-center gap-4 mb-6">
        <Shield className="w-8 h-8 text-blue-600" />
        <h1 className="text-3xl font-bold">Privacy Policy</h1>
      </div>
      <p className="text-gray-600">
        Last Updated: March 15, 2024
      </p>
      <p className="mt-4 text-gray-600">
        At Proaptus Solutions, we take your privacy seriously. This Privacy Policy 
        explains how we collect, use, disclose, and safeguard your information when 
        you visit our website.
      </p>
    </div>
  );
}