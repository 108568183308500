import React from 'react';
import { Calendar, User } from 'lucide-react';
import { Link } from '../layout/Link';
import type { NewsArticle } from '../../types/news';

interface NewsCardProps {
  article: NewsArticle;
}

export function NewsCard({ article }: NewsCardProps) {
  const { id, title, excerpt, imageUrl, date, author } = article;
  const formattedDate = new Date(date).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });

  return (
    <article className="bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300">
      {imageUrl && (
        <div className="aspect-w-16 aspect-h-9 rounded-t-xl overflow-hidden">
          <img 
            src={imageUrl} 
            alt={title}
            className="w-full h-full object-cover"
          />
        </div>
      )}
      
      <div className="p-6">
        <h2 className="text-xl font-bold mb-3 line-clamp-2">
          <Link href={`/news/${id}`} className="hover:text-blue-600">
            {title}
          </Link>
        </h2>

        <div className="flex items-center text-sm text-gray-500 mb-4 space-x-4">
          <span className="flex items-center">
            <Calendar className="w-4 h-4 mr-1" />
            {formattedDate}
          </span>
          <span className="flex items-center">
            <User className="w-4 h-4 mr-1" />
            {author}
          </span>
        </div>

        <p className="text-gray-600 mb-4 line-clamp-3">{excerpt}</p>

        <Link 
          href={`/news/${id}`}
          className="inline-flex items-center text-blue-600 hover:text-blue-700"
        >
          Read More
          <svg className="w-4 h-4 ml-1" viewBox="0 0 24 24" fill="none" stroke="currentColor">
            <path d="M5 12h14M12 5l7 7-7 7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </Link>
      </div>
    </article>
  );
}