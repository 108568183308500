import React from 'react';
import { Mic, Globe, Users } from 'lucide-react';

const developmentAreas = [
  {
    icon: Mic,
    title: 'Voice Assistant Integration',
    description: 'Adding hands-free capabilities for navigation and live updates to enhance accessibility.',
  },
  {
    icon: Globe,
    title: 'International Route Coverage',
    description: 'Expanding services to support global travel with multilingual functionality.',
  },
  {
    icon: Users,
    title: 'Operator Collaborations',
    description: 'Strengthening partnerships with transport providers to enhance live support and accessibility.',
  },
];

export function Development() {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-16">Development Focus</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto">
          {developmentAreas.map((area) => {
            const Icon = area.icon;
            return (
              <div key={area.title} className="text-center">
                <div className="inline-block p-4 bg-blue-50 rounded-full mb-6">
                  <Icon className="w-8 h-8 text-blue-600" />
                </div>
                <h3 className="text-xl font-semibold mb-4">{area.title}</h3>
                <p className="text-gray-600">{area.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}