import React from 'react';
import { Mail, Link as LinkIcon } from 'lucide-react';
import { Link } from '../../../components/layout/Link';

export function CookieFooter() {
  return (
    <div className="p-8 border-t bg-gray-50 rounded-b-xl">
      <div className="space-y-6">
        <div>
          <h2 className="text-xl font-semibold mb-3">Contact Us</h2>
          <p className="text-gray-600">
            If you have any questions about our Cookie Policy, please contact us at:
          </p>
          <a 
            href="mailto:privacy@proaptus.com"
            className="inline-flex items-center text-blue-600 hover:text-blue-700 mt-2"
          >
            <Mail className="w-4 h-4 mr-2" />
            privacy@proaptus.com
          </a>
        </div>

        <div>
          <h2 className="text-xl font-semibold mb-3">Related Documents</h2>
          <div className="space-y-2">
            <Link 
              href="/legal/privacy"
              className="inline-flex items-center text-blue-600 hover:text-blue-700"
            >
              <LinkIcon className="w-4 h-4 mr-2" />
              Privacy Policy
            </Link>
            <br />
            <Link 
              href="/legal/terms"
              className="inline-flex items-center text-blue-600 hover:text-blue-700"
            >
              <LinkIcon className="w-4 h-4 mr-2" />
              Terms and Conditions
            </Link>
          </div>
        </div>

        <p className="text-sm text-gray-500 italic">
          This Cookie Policy is for informational purposes and may be updated periodically.
          Please check back regularly for updates.
        </p>
      </div>
    </div>
  );
}