import React from 'react';
import { Lightbulb, Brain, Bus, Radio } from 'lucide-react';

const innovations = [
  {
    icon: Brain,
    title: 'Multi-Agent AI Framework',
    description: 'Designed to reduce costs and accelerate AI deployment across infrastructure systems.',
  },
  {
    icon: Bus,
    title: 'Bus Franchising Technology',
    description: 'Transformed Greater Manchester\'s public transport through modular, data-driven systems.',
  },
  {
    icon: Radio,
    title: '4G Edge Computing',
    description: 'Enhanced satellite communication systems for emergency services.',
  },
];

export function Innovations() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <Lightbulb className="w-12 h-12 text-blue-600 mx-auto mb-4" />
          <h2 className="text-3xl font-bold mb-4">Key Innovations</h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Driving innovation through technology and expertise.
          </p>
        </div>
        <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
          {innovations.map((innovation) => {
            const Icon = innovation.icon;
            return (
              <div key={innovation.title} className="bg-white p-6 rounded-lg shadow-sm">
                <Icon className="w-8 h-8 text-blue-600 mb-4" />
                <h3 className="text-lg font-semibold mb-3">{innovation.title}</h3>
                <p className="text-gray-600">{innovation.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}