import React from 'react';
import { Radio, Thermometer, Brain } from 'lucide-react';

const features = [
  {
    icon: Radio,
    title: 'Distributed Acoustic Sensing (DAS)',
    description: 'Monitor vehicle movement, road vibrations, and traffic congestion in real time. Identify unusual patterns, accidents, or hazardous conditions on roadways.',
  },
  {
    icon: Thermometer,
    title: 'Distributed Temperature Sensing (DTS)',
    description: 'Detect overheating, cable damage, and environmental anomalies in ducts. Enable quick responses to potential failures, reducing downtime.',
  },
  {
    icon: Brain,
    title: 'AI-Powered Analytics',
    description: 'Provide real-time anomaly detection for traffic incidents, fibre blockages, and structural failures. Use data-driven insights to optimize traffic management.',
  },
];

export function CoreFeatures() {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-16">Core Features</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {features.map((feature) => {
            const Icon = feature.icon;
            return (
              <div key={feature.title} className="text-center">
                <div className="inline-block p-4 bg-blue-50 rounded-full mb-6">
                  <Icon className="w-8 h-8 text-blue-600" />
                </div>
                <h3 className="text-xl font-semibold mb-4">{feature.title}</h3>
                <p className="text-gray-600 leading-relaxed">{feature.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}