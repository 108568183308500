import React from 'react';
import { Microscope, Network, Building } from 'lucide-react';

const researchAreas = [
  {
    icon: Microscope,
    title: 'Enhanced Micro-Seismic Monitoring',
    description: 'Refining capabilities to detect and analyze seismic activity more accurately.',
  },
  {
    icon: Network,
    title: 'Advanced Traffic Analytics',
    description: 'Expanding AI models to handle complex urban traffic conditions.',
  },
  {
    icon: Building,
    title: 'Smart City Integration',
    description: 'Developing frameworks to support emerging smart city technologies, enabling seamless connectivity.',
  },
];

export function ResearchDevelopment() {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-16">R&D Focus</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto">
          {researchAreas.map((area) => {
            const Icon = area.icon;
            return (
              <div key={area.title} className="text-center">
                <div className="inline-block p-4 bg-blue-50 rounded-full mb-6">
                  <Icon className="w-8 h-8 text-blue-600" />
                </div>
                <h3 className="text-xl font-semibold mb-4">{area.title}</h3>
                <p className="text-gray-600">{area.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}