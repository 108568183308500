import React from 'react';
import { MetaTags } from '../../components/common/MetaTags';
import { CookieHeader } from './components/CookieHeader';
import { CookieSection } from './components/CookieSection';
import { CookieFooter } from './components/CookieFooter';

export function CookiePolicy() {
  return (
    <>
      <MetaTags
        title="Cookie Policy"
        description="Understand how Proaptus uses cookies to enhance your browsing experience."
        canonicalUrl="https://www.proaptus.co.uk/legal/cookie-policy"
      />
      <div className="min-h-screen bg-gray-50 py-12 px-4">
        <div className="max-w-3xl mx-auto bg-white rounded-xl shadow-sm">
          <CookieHeader />
          <CookieSection />
          <CookieFooter />
        </div>
      </div>
    </>
  );
}