import React, { useEffect } from 'react';

export function HubspotTracking() {
  useEffect(() => {
    // Create and load the HubSpot tracking script
    const script = document.createElement('script');
    script.id = 'hs-script-loader';
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.src = '//js-eu1.hs-scripts.com/144562262.js';

    // Add script to document
    document.body.appendChild(script);

    // Cleanup on unmount
    return () => {
      const existingScript = document.getElementById('hs-script-loader');
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    };
  }, []);

  return null;
}