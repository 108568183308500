import React from 'react';
import { Brain, Smartphone, MessageSquare, Accessibility } from 'lucide-react';

const features = [
  {
    icon: Brain,
    title: 'Agentic AI',
    description: 'Personalized itinerary adjustments and live updates based on individual preferences, adapting to real-time changes for seamless travel experiences.',
  },
  {
    icon: Smartphone,
    title: 'Mobile-First Design',
    description: 'Intuitive interface designed for on-the-go access, including step-free route planning and ramp/elevator locators for enhanced navigation.',
  },
  {
    icon: MessageSquare,
    title: 'Real-Time Communication',
    description: 'Live notifications for delays, cancellations, and alternate routes, with seamless interaction between travelers and transport operators.',
  },
  {
    icon: Accessibility,
    title: 'Accessibility Enhancements',
    description: 'Step-free routes, visual and auditory notifications, and text-to-voice capabilities for truly inclusive travel experiences.',
  },
];

export function KeyFeatures() {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-16">Key Features</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature) => {
            const Icon = feature.icon;
            return (
              <div key={feature.title} className="text-center">
                <div className="inline-block p-4 bg-blue-50 rounded-full mb-6">
                  <Icon className="w-8 h-8 text-blue-600" />
                </div>
                <h3 className="text-xl font-semibold mb-4">{feature.title}</h3>
                <p className="text-gray-600 leading-relaxed">{feature.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}