import React from 'react';
import { Network } from 'lucide-react';

export function AdaptiveEdgeSecurity() {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-12">
            <Network className="w-12 h-12 text-blue-600 mx-auto mb-4" />
            <h2 className="text-3xl font-bold mb-4">Adaptive Edge Security (AES)</h2>
            <p className="text-xl text-gray-600">
              Our unique approach to distributed monitoring and resilient security
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-gray-50 p-6 rounded-lg">
              <h3 className="font-semibold mb-3">Distributed Monitoring</h3>
              <p className="text-gray-600">
                Utilizes DAS and drones to collect data from multiple points simultaneously
              </p>
            </div>
            
            <div className="bg-gray-50 p-6 rounded-lg">
              <h3 className="font-semibold mb-3">Resilience</h3>
              <p className="text-gray-600">
                Maintains monitoring and alerting even if part of the network is compromised
              </p>
            </div>
            
            <div className="bg-gray-50 p-6 rounded-lg">
              <h3 className="font-semibold mb-3">Edge AI Integration</h3>
              <p className="text-gray-600">
                Processes data locally at the edge for faster decision-making and reduced latency
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}