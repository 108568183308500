import React from 'react';
import type { LogoProps } from './types';
import { LOGO_PATHS } from './types';

export function LogoImage({ 
  className = '', 
  height = 48,
  onError,
  onLoad 
}: LogoProps) {
  // Calculate width based on the original aspect ratio (2048:809)
  const aspectRatio = 2048 / 809;
  const width = height * aspectRatio;

  return (
    <img 
      src={LOGO_PATHS.MAIN}
      alt="Proaptus" 
      className={`w-auto ${className}`}
      style={{ 
        height: `${height}px`,
        width: `${width}px`
      }}
      onError={onError}
      onLoad={onLoad}
    />
  );
}