import React from 'react';
import { LogoImage } from './LogoImage';
import { LogoFallback } from './LogoFallback';
import type { LogoProps } from './types';

export function Logo({ 
  className = '', 
  height = 48,
  onError,
  onLoad 
}: LogoProps) {
  const [error, setError] = React.useState(false);

  const handleError = () => {
    console.error('Logo failed to load');
    setError(true);
    onError?.();
  };

  const handleLoad = () => {
    setError(false);
    onLoad?.();
  };

  if (error) {
    return <LogoFallback className={className} height={height} />;
  }

  return (
    <div className={`flex items-center ${className}`}>
      <LogoImage 
        className={className}
        height={height}
        onError={handleError}
        onLoad={handleLoad}
      />
    </div>
  );
}