export const GOOGLE_MAPS_API_KEY = 'AIzaSyCBe04wFDsxqG3DsqH8u9wr1zBL6yRTKJw';

export const OFFICE_LOCATION = {
  lat: 52.9225,
  lng: -1.4746
};

export const MAP_STYLES = [
  {
    featureType: 'all',
    elementType: 'geometry',
    stylers: [{ color: '#f5f5f5' }]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#e9e9e9' }]
  }
];

export const MAP_OPTIONS = {
  disableDefaultUI: false,
  zoomControl: true,
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false
};