import React from 'react';
import { MetaTags } from '../components/common/MetaTags';
import { SchemaMarkup } from '../components/common/SchemaMarkup';
import { ContactHero } from '../components/contact/ContactHero';
import { HubspotForm } from '../components/contact/HubspotForm';
import { ContactInfo } from '../components/contact/ContactInfo';
import { Map } from '../components/contact/Map';
import { FAQ } from '../components/contact/FAQ';

const faqSchema = {
  type: 'FAQPage' as const,
  questions: [
    {
      question: "What services does Proaptus provide?",
      answer: "Proaptus specializes in AI-driven solutions for infrastructure and transportation, including rail monitoring (Sentry), traffic flow analysis (Lighthouse), and accessible travel support (Novae.Travel). We combine fibre optic sensing, AI, and edge analytics to create smarter, safer operations."
    },
    {
      question: "How can I request a demo of your solutions?",
      answer: "You can request a demo by filling out our contact form or emailing us directly at contact@proaptus.co.uk. Please specify which solution you're interested in (Sentry, Lighthouse, or Novae.Travel), and our team will arrange a personalized demonstration."
    },
    {
      question: "Do you offer custom solutions for specific infrastructure needs?",
      answer: "Yes, we work closely with clients to develop tailored solutions that address their specific infrastructure and transportation challenges. Our team can adapt our existing platforms or create custom solutions using our core technologies."
    }
  ]
};

export function Contact() {
  return (
    <>
      <MetaTags
        title="Contact Proaptus: AI Infrastructure Solutions"
        description="Get in touch with Proaptus for innovative AI solutions in infrastructure and transportation. Transform your operations with our expertise."
      />
      <SchemaMarkup schema={faqSchema} />
      <main className="flex-grow">
        <h1 className="sr-only">Contact Proaptus: Transform Your Infrastructure with AI</h1>
        <ContactHero />
        
        <div className="container mx-auto px-4 py-20">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            <HubspotForm />
            <div className="space-y-8">
              <ContactInfo />
              <Map />
            </div>
          </div>
        </div>

        <FAQ />
      </main>
    </>
  );
}