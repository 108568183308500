import React from 'react';
import { Radio, Brain, Cpu, Building } from 'lucide-react';

const expertiseAreas = [
  {
    icon: Radio,
    title: 'Fibre Optic Sensing',
    description: 'Leveraging DAS and DTS technologies for real-time monitoring.',
  },
  {
    icon: Brain,
    title: 'Agentic AI',
    description: 'Deploying multi-agent systems for predictive analytics and automation.',
  },
  {
    icon: Cpu,
    title: 'Edge Analytics',
    description: 'Providing actionable insights directly at the point of data collection.',
  },
  {
    icon: Building,
    title: 'Large-Scale Digital Transformations',
    description: 'Delivering complex programs like the Bee Network transformation in Manchester.',
  },
];

export function Expertise() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold">Specialists in AI, Fibre Optics, and Infrastructure Intelligence</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {expertiseAreas.map((area) => {
            const Icon = area.icon;
            return (
              <div key={area.title} className="bg-white p-6 rounded-lg shadow-sm">
                <Icon className="w-8 h-8 text-blue-600 mb-4" />
                <h3 className="text-lg font-semibold mb-3">{area.title}</h3>
                <p className="text-gray-600">{area.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}