import React from 'react';
import { History } from 'lucide-react';
import { MaxWidthContainer } from '../common/layout/MaxWidthContainer';

export function AboutHero() {
  return (
    <div className="relative bg-gradient-to-br from-gray-900 to-blue-900 text-white pt-24">
      <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80')] bg-cover bg-center mix-blend-overlay opacity-20"></div>
      <MaxWidthContainer className="relative py-20">
        <div className="max-w-3xl">
          <History className="w-12 h-12 mb-6" />
          <h1 className="text-4xl sm:text-5xl font-bold mb-6">
            Pioneering AI & Fibre-Optic Sensing Since 2018
          </h1>
          <p className="text-xl text-gray-300">
            Transforming infrastructure through innovative, sustainable, and intelligent solutions.
          </p>
        </div>
      </MaxWidthContainer>
    </div>
  );
}