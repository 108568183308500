import React from 'react';
import { Container } from '../common/layout/Container';
import { HeroContent } from './hero/HeroContent';
import { HeroBackground } from './hero/HeroBackground';

export function Hero() {
  return (
    <section className="relative min-h-screen bg-[#121212]">
      {/* Background with gradient overlay */}
      <HeroBackground />
      
      {/* Content */}
      <Container className="relative z-10 min-h-screen flex items-center">
        <div className="w-full lg:w-3/5">
          <HeroContent />
        </div>
      </Container>
    </section>
  );
}