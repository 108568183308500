import React from 'react';
import { useInView } from '../../../hooks/useInView';

interface SlideInProps {
  children: React.ReactNode;
  direction?: 'up' | 'down' | 'left' | 'right';
  delay?: number;
  className?: string;
}

export function SlideIn({ 
  children, 
  direction = 'up', 
  delay = 0,
  className = '' 
}: SlideInProps) {
  const { ref, isVisible } = useInView();

  const getTransform = (visible: boolean) => {
    const distance = '30px';
    switch (direction) {
      case 'up': return visible ? 'translateY(0)' : `translateY(${distance})`;
      case 'down': return visible ? 'translateY(0)' : `translateY(-${distance})`;
      case 'left': return visible ? 'translateX(0)' : `translateX(${distance})`;
      case 'right': return visible ? 'translateX(0)' : `translateX(-${distance})`;
    }
  };

  return (
    <div
      ref={ref}
      className={`transition-all duration-500 ${className}`}
      style={{
        opacity: isVisible ? 1 : 0,
        transform: getTransform(isVisible),
        transitionDelay: `${delay}ms`
      }}
    >
      {children}
    </div>
  );
}