import React from 'react';
import { MapPin } from 'lucide-react';
import { AnimateOnScroll } from '../common/animations/AnimateOnScroll';
import { GoogleMap } from './map/GoogleMap';

const OFFICE_LOCATION = {
  lat: 52.9225,
  lng: -1.4746
};

export function Map() {
  return (
    <AnimateOnScroll animation="animate-slide-up" delay={400}>
      <div className="bg-white p-8 rounded-xl shadow-sm">
        <h2 className="text-2xl font-bold mb-6">Find Us</h2>
        <div className="relative">
          <div className="aspect-w-16 aspect-h-9">
            <GoogleMap center={OFFICE_LOCATION} />
          </div>
          <div className="mt-4">
            <p className="text-gray-600">
              1 Derwent Business Centre, Clarke Street, Derby, DE1 2BU
            </p>
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${OFFICE_LOCATION.lat},${OFFICE_LOCATION.lng}`}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center text-blue-600 hover:text-blue-700 mt-2"
            >
              View on Google Maps
              <MapPin className="w-4 h-4 ml-2" />
            </a>
          </div>
        </div>
      </div>
    </AnimateOnScroll>
  );
}