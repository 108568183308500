import React from 'react';
import { Search } from 'lucide-react';

export function NewsFilters() {
  return (
    <div className="flex flex-col sm:flex-row gap-4 items-center justify-between">
      <div className="relative flex-grow max-w-md">
        <input
          type="search"
          placeholder="Search articles..."
          className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
        <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
      </div>
      
      <select className="px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent">
        <option value="">All Categories</option>
        <option value="ai">AI & Innovation</option>
        <option value="infrastructure">Infrastructure</option>
        <option value="transport">Transportation</option>
      </select>
    </div>
  );
}