import React from 'react';
import { TermsContent } from './TermsContent';

const sections = [
  {
    title: '1. Acceptance of Terms',
    content: `Welcome to Proaptus Solutions (the "Company"). By accessing or using our website,
      you agree to be bound by these Terms and Conditions (the "Terms"). If you do not
      agree with these Terms, please do not use our website.`
  },
  {
    title: '2. Intellectual Property',
    content: `All content, features, and functionality on this website (including but not limited
      to text, graphics, and logos) are the exclusive property of Proaptus Solutions and
      are protected by applicable copyright, trademark, and other laws.`
  },
  {
    title: '3. Use of Services',
    content: `Our services are provided "as is" and "as available" basis. You agree to use our
      services only for lawful purposes and in accordance with these Terms. You are
      responsible for maintaining the confidentiality of your account information.`
  },
  {
    title: '4. Data Protection',
    content: `We are committed to protecting your personal data in accordance with applicable
      data protection laws. Please refer to our Privacy Policy for detailed information
      about how we collect, use, and protect your data.`
  },
  {
    title: '5. Limitation of Liability',
    content: `The Company shall not be liable for any direct, indirect, incidental, or consequential
      damages arising out of or related to the use or inability to use this website or our
      services.`
  },
  {
    title: '6. Changes to Terms',
    content: `We reserve the right to update or modify these Terms at any time without prior notice.
      Your continued use of the site constitutes your acceptance of any changes.`
  }
];

export function TermsSection() {
  return (
    <div className="p-8">
      <div className="space-y-8">
        {sections.map((section) => (
          <TermsContent
            key={section.title}
            title={section.title}
            content={section.content}
          />
        ))}
      </div>
    </div>
  );
}