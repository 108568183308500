import { useEffect, useCallback } from 'react';

// Report Web Vitals
export function reportWebVitals(metric: any) {
  // Analytics
  console.log(metric);
}

// Lazy loading images
export function useLazyImages() {
  const loadImage = useCallback((entry: IntersectionObserverEntry) => {
    const img = entry.target as HTMLImageElement;
    const src = img.getAttribute('data-src');
    if (src) {
      img.src = src;
      img.removeAttribute('data-src');
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            loadImage(entry);
            observer.unobserve(entry.target);
          }
        });
      },
      { rootMargin: '50px' }
    );

    const images = document.querySelectorAll('img[data-src]');
    images.forEach((img) => observer.observe(img));

    return () => observer.disconnect();
  }, [loadImage]);
}

// Preload critical resources
export function preloadResources() {
  const resources = [
    { href: '/fonts/inter-var.woff2', as: 'font', type: 'font/woff2' },
    { href: 'https://images.unsplash.com', as: 'dns-prefetch' },
    { href: 'https://js-eu1.hs-scripts.com', as: 'dns-prefetch' }
  ];

  resources.forEach(({ href, as, type }) => {
    const link = document.createElement('link');
    link.rel = as === 'dns-prefetch' ? 'dns-prefetch' : 'preload';
    link.href = href;
    if (as !== 'dns-prefetch') link.as = as;
    if (type) link.type = type;
    if (as === 'font') link.crossOrigin = 'anonymous';
    document.head.appendChild(link);
  });
}