import React, { useEffect, useRef, useState } from 'react';

declare global {
  interface Window {
    hbspt?: {
      forms: {
        create: (opts: {
          portalId: string;
          formId: string;
          target: string;
          region?: string;
          onFormSubmit?: () => void;
          onFormReady?: () => void;
        }) => void;
      };
    };
  }
}

export function HubspotForm() {
  const formContainerRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let scriptElement: HTMLScriptElement | null = null;

    const loadForm = () => {
      if (!window.hbspt || !formContainerRef.current) return;

      // Clear any existing form content
      formContainerRef.current.innerHTML = '';
      
      try {
        window.hbspt.forms.create({
          portalId: '144562262',
          formId: 'b63772f7-7d8a-462d-b618-ff8f8a499f6c',
          target: '#hubspot-form-container',
          region: 'eu1',
          onFormReady: () => {
            setIsLoading(false);
            setError(null);
          },
          onFormSubmit: () => {
            console.log('Form submitted successfully');
          }
        });
      } catch (err) {
        setError('Failed to load form. Please try again later.');
        setIsLoading(false);
      }
    };

    const initializeForm = () => {
      // Create and load the HubSpot script
      scriptElement = document.createElement('script');
      scriptElement.src = 'https://js-eu1.hsforms.net/forms/v2.js';
      scriptElement.async = true;
      
      scriptElement.onload = loadForm;
      scriptElement.onerror = () => {
        setError('Failed to load form. Please try again later.');
        setIsLoading(false);
      };

      document.body.appendChild(scriptElement);
    };

    // Initialize form
    initializeForm();

    return () => {
      // Cleanup
      if (formContainerRef.current) {
        formContainerRef.current.innerHTML = '';
      }
      if (scriptElement && document.body.contains(scriptElement)) {
        document.body.removeChild(scriptElement);
      }
    };
  }, []);

  return (
    <div className="bg-white p-8 rounded-xl shadow-sm">
      <h2 className="text-2xl font-bold mb-6">Send us a Message</h2>
      
      {error && (
        <div className="mb-6 p-4 bg-red-50 text-red-700 rounded-lg">
          {error}
        </div>
      )}

      {isLoading && (
        <div className="flex items-center justify-center h-[400px]">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        </div>
      )}
      
      <div 
        id="hubspot-form-container" 
        ref={formContainerRef}
        className={isLoading ? 'hidden' : 'block'}
        style={{ minHeight: '400px' }}
      />
    </div>
  );
}