import React from 'react';
import { LucideIcon } from 'lucide-react';

interface TeamMemberProps {
  name: string;
  role: string;
  description: string;
  icon: LucideIcon;
  imageSrc?: string;
  linkedIn?: string;
  qrProfile?: string;
  size?: 'normal' | 'large';
}

export function TeamMember({ 
  name, 
  role, 
  description, 
  icon: Icon, 
  imageSrc,
  linkedIn,
  qrProfile,
  size = 'normal' 
}: TeamMemberProps) {
  const imageSize = size === 'large' ? 'w-40 h-40' : 'w-32 h-32';
  const containerSize = size === 'large' ? 'p-8' : 'p-6';

  return (
    <div className={`bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300 ${containerSize}`}>
      <div className="flex flex-col items-center">
        {imageSrc ? (
          <div className={`${imageSize} rounded-full overflow-hidden mb-6 ring-4 ring-blue-50`}>
            <img 
              src={imageSrc} 
              alt={name} 
              className="w-full h-full object-cover"
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.onerror = null;
                target.src = `https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=0D8ABC&color=fff&size=200`;
              }}
            />
          </div>
        ) : (
          <div className={`${imageSize} rounded-full bg-gradient-to-br from-blue-50 to-blue-100 mb-6 flex items-center justify-center`}>
            <Icon className="w-12 h-12 text-blue-600" />
          </div>
        )}
        
        <div className="text-center">
          <h3 className="text-xl font-bold text-gray-900 mb-1">{name}</h3>
          <p className="text-blue-600 font-medium mb-4">{role}</p>
          <p className="text-gray-600 leading-relaxed">{description}</p>
          
          <div className="flex flex-col items-center gap-2 mt-4">
            {linkedIn && (
              <a
                href={linkedIn}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center text-blue-600 hover:text-blue-700 transition-colors"
                aria-label="Connect on LinkedIn"
              >
                <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                </svg>
                Connect on LinkedIn
              </a>
            )}
            {qrProfile && (
              <a
                href={qrProfile}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-700 transition-colors"
                aria-label="View Digital Profile"
              >
                View Digital Profile
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}