import React from 'react';

export function Mission() {
  return (
    <div>
      <h3 className="font-semibold mb-3">Mission</h3>
      <p className="text-gray-600">
        At Proaptus, we are committed to transforming critical infrastructure through AI 
        innovation, creating scalable, efficient, and sustainable solutions.
      </p>
    </div>
  );
}