import React from 'react';
import { LighthouseHero } from '../../components/solutions/lighthouse/LighthouseHero';
import { CoreFeatures } from '../../components/solutions/lighthouse/CoreFeatures';
import { InfrastructureMonitoringSection } from '../../components/solutions/lighthouse/video-sections/InfrastructureMonitoringSection';
import { AnalyticsDashboardSection } from '../../components/solutions/lighthouse/video-sections/AnalyticsDashboardSection';
import { Applications } from '../../components/solutions/lighthouse/Applications';
import { ComparisonSection } from '../../components/solutions/lighthouse/ComparisonSection';
import { SpecialistUseCases } from '../../components/solutions/lighthouse/SpecialistUseCases';
import { Advantages } from '../../components/solutions/lighthouse/Advantages';
import { ResearchDevelopment } from '../../components/solutions/lighthouse/ResearchDevelopment';

export function Lighthouse() {
  return (
    <div>
      <LighthouseHero />
      <CoreFeatures />
      <InfrastructureMonitoringSection />
      <AnalyticsDashboardSection />
      <Applications />
      <ComparisonSection />
      <SpecialistUseCases />
      <Advantages />
      <ResearchDevelopment />
    </div>
  );
}