import React from 'react';
import { Link } from '../Link';

const sections = [
  {
    title: 'Solutions',
    links: [
      { href: '/solutions/sentry', label: 'Sentry' },
      { href: '/solutions/lighthouse', label: 'Lighthouse' },
      { href: '/solutions/novae-travel', label: 'Novae.Travel' },
      { href: '/solutions/bus-reform', label: 'Bus Reform' }
    ]
  },
  {
    title: 'Company',
    links: [
      { href: '/about', label: 'About Us' },
      { href: '/news', label: 'News' },
      { href: '/contact', label: 'Contact' }
    ]
  },
  {
    title: 'Legal',
    links: [
      { href: '/legal/terms-and-conditions', label: 'Terms & Conditions' },
      { href: '/legal/privacy-policy', label: 'Privacy Policy' },
      { href: '/legal/cookie-policy', label: 'Cookie Policy' }
    ]
  }
];

export function FooterLinks() {
  return (
    <>
      {sections.map((section) => (
        <div key={section.title}>
          <h4 className="text-lg font-semibold text-white mb-4">{section.title}</h4>
          <ul className="space-y-2">
            {section.links.map((link) => (
              <li key={link.href}>
                <Link 
                  href={link.href} 
                  className="hover:text-blue-400 transition-colors"
                >
                  {link.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </>
  );
}