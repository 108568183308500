import React from 'react';
import { AlertCircle, Train, Box } from 'lucide-react';

const useCases = [
  {
    icon: AlertCircle,
    title: 'High-Risk Corridors',
    description: 'Enhanced monitoring in areas prone to environmental hazards or trespassing.',
  },
  {
    icon: Train,
    title: 'Urban Rail Systems',
    description: 'Optimized operations in high-traffic networks to prevent delays.',
  },
  {
    icon: Box,
    title: 'Freight Rail Operations',
    description: 'Secured cargo and monitored rail conditions to reduce derailment risks.',
  },
];

export function UseCases() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-16">Use Cases</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto">
          {useCases.map((useCase) => {
            const Icon = useCase.icon;
            return (
              <div key={useCase.title} className="bg-white p-6 rounded-lg shadow-sm">
                <Icon className="w-8 h-8 text-blue-600 mb-4" />
                <h3 className="text-lg font-semibold mb-3">{useCase.title}</h3>
                <p className="text-gray-600">{useCase.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}