import React from 'react';
import { Video } from '../../../common/video/Video';
import { BarChart, AlertTriangle, Zap } from 'lucide-react';

const features = [
  {
    icon: BarChart,
    title: 'Advanced Analytics',
    description: 'Comprehensive data visualization and trend analysis'
  },
  {
    icon: AlertTriangle,
    title: 'Anomaly Detection',
    description: 'AI-powered identification of infrastructure issues'
  },
  {
    icon: Zap,
    title: 'Real-Time Updates',
    description: 'Instant alerts and notifications for critical events'
  }
];

export function AnalyticsDashboardSection() {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div className="order-2 lg:order-1">
              <div className="space-y-8">
                {/* Video Section */}
                <div className="rounded-xl overflow-hidden shadow-lg">
                  <Video
                    src="https://firebasestorage.googleapis.com/v0/b/proaptus-website.firebasestorage.app/o/248450_medium.mp4?alt=media&token=681c86d9-7c7a-468f-b3b2-1ae568c868ee"
                    fallbackImage="https://images.unsplash.com/photo-1465447142348-e9952c393450?auto=format&fit=crop&q=80"
                    className="w-full aspect-video object-cover"
                  />
                </div>

                {/* Dashboard Image */}
                <div className="rounded-xl overflow-hidden shadow-lg">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/proaptus-website.firebasestorage.app/o/Beautiful.ai%20-%20Novae%20Lighthouse%20Pitch%20Deck%20-%20Oct%202024%20-%20Slide%205.jpeg?alt=media&token=ceea23d8-5992-4d36-9efb-3d1ed5a20b66"
                    alt="Lighthouse Analytics Dashboard"
                    className="w-full h-auto"
                  />
                </div>
              </div>
            </div>
            
            <div className="order-1 lg:order-2">
              <h2 className="text-3xl font-bold mb-6">Intelligent Analytics</h2>
              <p className="text-xl text-gray-600 mb-8">
                Transform raw data into actionable insights with our advanced analytics 
                dashboard, providing comprehensive visibility into your infrastructure.
              </p>
              
              <div className="space-y-6">
                {features.map((feature) => {
                  const Icon = feature.icon;
                  return (
                    <div key={feature.title} className="flex items-start space-x-4">
                      <div className="p-2 bg-blue-50 rounded-lg">
                        <Icon className="w-6 h-6 text-blue-600" />
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
                        <p className="text-gray-600">{feature.description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}