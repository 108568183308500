import React from 'react';
import { History } from 'lucide-react';

export function Story() {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div>
            <History className="w-12 h-12 text-blue-600 mb-4" />
            <h2 className="text-3xl font-bold mb-6">Pioneering AI & Fibre-Optic Sensing Since 2018</h2>
            <p className="text-xl text-gray-600">
              Founded by Chinedu Achara, Proaptus began with a vision to transform critical 
              infrastructure through advanced technologies like fibre optic sensing, agentic AI, 
              and edge analytics. Early R&D successes laid the groundwork for pioneering solutions 
              like Sentry, Lighthouse, and Novae.Travel, which continue to redefine safety, 
              efficiency, and accessibility across transportation and infrastructure.
            </p>
          </div>
          <div className="relative h-[400px] rounded-lg overflow-hidden">
            <img 
              src="https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80" 
              alt="Digital innovation and technology advancement visualization" 
              className="absolute inset-0 w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    </section>
  );
}