import React from 'react';
import { MetaTags } from '../../components/common/MetaTags';
import { TermsHeader } from './components/TermsHeader';
import { TermsSection } from './components/TermsSection';
import { TermsFooter } from './components/TermsFooter';

export function TermsAndConditions() {
  return (
    <>
      <MetaTags
        title="Terms and Conditions"
        description="Read about the terms and conditions governing the use of Proaptus services."
        canonicalUrl="https://www.proaptus.co.uk/legal/terms-and-conditions"
      />
      <div className="min-h-screen bg-gray-50 py-12 px-4">
        <div className="max-w-3xl mx-auto bg-white rounded-xl shadow-sm">
          <TermsHeader />
          <TermsSection />
          <TermsFooter />
        </div>
      </div>
    </>
  );
}