import React from 'react';
import { Video } from '../../../common/video/Video';

export function SeamlessExperienceSection() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
            <div className="order-2 md:order-1">
              <div className="rounded-xl overflow-hidden shadow-lg">
                <Video
                  src="https://firebasestorage.googleapis.com/v0/b/proaptus-website.firebasestorage.app/o/Facebook%202.mp4?alt=media&token=46fb3abf-bb4a-4044-9ac4-18cb7c07e34e"
                  fallbackImage="https://images.unsplash.com/photo-1488085061387-422e29b40080?auto=format&fit=crop&q=80"
                  className="w-full aspect-video object-cover"
                />
              </div>
            </div>
            <div className="order-1 md:order-2">
              <h2 className="text-3xl font-bold mb-6">Seamless Travel Experience</h2>
              <p className="text-xl text-gray-600 mb-8">
                Navigate your journey effortlessly with our AI-powered assistance, 
                providing real-time guidance and support throughout your travels.
              </p>
              <ul className="space-y-4 text-gray-600">
                <li className="flex items-start">
                  <span className="w-2 h-2 mt-2 rounded-full bg-blue-600 mr-3" />
                  <span>24/7 AI assistance for instant support</span>
                </li>
                <li className="flex items-start">
                  <span className="w-2 h-2 mt-2 rounded-full bg-blue-600 mr-3" />
                  <span>Smart notifications for important updates</span>
                </li>
                <li className="flex items-start">
                  <span className="w-2 h-2 mt-2 rounded-full bg-blue-600 mr-3" />
                  <span>Seamless integration with travel services</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}