import React from 'react';
import { MapPin } from 'lucide-react';
import { OFFICE_LOCATION } from './GoogleMapConfig';

interface MapFallbackProps {
  error?: boolean;
}

export function MapFallback({ error }: MapFallbackProps) {
  return (
    <div className="w-full h-full bg-gray-100 rounded-lg flex items-center justify-center">
      <div className="text-center p-8">
        <MapPin className="w-8 h-8 text-gray-400 mx-auto mb-4" />
        <p className="text-gray-600">
          {error ? 'Unable to load map' : 'Loading map...'}
        </p>
        {error && (
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${OFFICE_LOCATION.lat},${OFFICE_LOCATION.lng}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-700 mt-2 inline-block"
          >
            View on Google Maps
          </a>
        )}
      </div>
    </div>
  );
}