import React from 'react';
import { LucideIcon } from 'lucide-react';

interface InspectionFeatureProps {
  icon: LucideIcon;
  title: string;
  description: string;
}

export function InspectionFeature({ icon: Icon, title, description }: InspectionFeatureProps) {
  return (
    <div className="flex items-start space-x-4">
      <div className="p-2 bg-blue-50 rounded-lg">
        <Icon className="w-6 h-6 text-blue-600" />
      </div>
      <div>
        <h3 className="text-lg font-semibold mb-2">{title}</h3>
        <p className="text-gray-600">{description}</p>
      </div>
    </div>
  );
}