import React from 'react';
import { Helmet } from 'react-helmet-async';
import type { MetaTagsConfig } from '../../utils/seo';

interface MetaTagsProps extends MetaTagsConfig {
  canonicalUrl?: string;
}

export function MetaTags({ 
  title,
  description,
  image,
  url = 'https://www.proaptus.co.uk',
  type = 'website',
  canonicalUrl
}: MetaTagsProps) {
  const fullTitle = `${title} | Proaptus`;
  
  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title>{fullTitle}</title>
      <meta name="description" content={description} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content={type} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />
      {image && <meta property="og:image" content={image} />}

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={description} />
      {image && <meta name="twitter:image" content={image} />}

      {/* Canonical URL */}
      <link rel="canonical" href={canonicalUrl || url} />
    </Helmet>
  );
}