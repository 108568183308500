import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import { setupA11y } from './utils/accessibility';
import { preloadResources } from './utils/performance';
import './index.css';
import './styles/loading-animations.css';
import './styles/animations.css';

// Import tests in development
if (process.env.NODE_ENV === 'development') {
  import('./utils/testing');
}

// Setup accessibility features
setupA11y();

// Preload critical resources
preloadResources();

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </StrictMode>
);