import React from 'react';
import { Video } from '../../../common/video/Video';
import { Activity, AlertTriangle, LineChart } from 'lucide-react';

const features = [
  {
    icon: Activity,
    title: 'Real-Time Monitoring',
    description: 'Track fleet performance and service delivery in real-time'
  },
  {
    icon: AlertTriangle,
    title: 'Proactive Alerts',
    description: 'Early warning system for potential service disruptions'
  },
  {
    icon: LineChart,
    title: 'Performance Analytics',
    description: 'Comprehensive insights into network efficiency and compliance'
  }
];

export function NetworkMonitoringSection() {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-3xl font-bold mb-6">Network Monitoring</h2>
              <p className="text-xl text-gray-600 mb-8">
                Advanced monitoring systems provide real-time visibility across your entire bus network,
                enabling proactive management and efficient service delivery.
              </p>
              
              <div className="space-y-6">
                {features.map((feature) => {
                  const Icon = feature.icon;
                  return (
                    <div key={feature.title} className="flex items-start space-x-4">
                      <div className="p-2 bg-blue-50 rounded-lg">
                        <Icon className="w-6 h-6 text-blue-600" />
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
                        <p className="text-gray-600">{feature.description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            
            <div className="rounded-xl overflow-hidden shadow-lg">
              <Video
                src="https://firebasestorage.googleapis.com/v0/b/proaptus-website.firebasestorage.app/o/3359890-hd_1280_720_30fps.mp4?alt=media&token=a7aa57e9-f28d-4447-8807-c881436cc3a2"
                fallbackImage="https://images.unsplash.com/photo-1570125909232-eb263c188f7e?auto=format&fit=crop&q=80"
                className="w-full aspect-video object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}