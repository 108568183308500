import React from 'react';
import { Link } from '../layout/Link';
import { ArrowRight } from 'lucide-react';

export function AboutSnapshot() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto">
          <div className="text-center mb-8">
            <h2 className="text-3xl font-bold mb-6">Track Record</h2>
            <p className="text-xl text-gray-600">
              Transforming infrastructure and transportation through innovative AI solutions 
              and proven expertise.
            </p>
          </div>
          
          <div className="bg-white p-8 rounded-lg shadow-sm mb-8">
            <ul className="space-y-4">
              <li className="flex items-start">
                <span className="w-2 h-2 mt-2 rounded-full bg-blue-600 mr-3"></span>
                <p className="text-gray-600">Successfully delivered Bee Network bus franchising technology services and operations</p>
              </li>
              <li className="flex items-start">
                <span className="w-2 h-2 mt-2 rounded-full bg-blue-600 mr-3"></span>
                <p className="text-gray-600">Developed innovative multi-agent AI frameworks for infrastructure monitoring</p>
              </li>
              <li className="flex items-start">
                <span className="w-2 h-2 mt-2 rounded-full bg-blue-600 mr-3"></span>
                <p className="text-gray-600">Secured Innovate UK funding for groundbreaking AI research</p>
              </li>
            </ul>
          </div>

          <div className="text-center">
            <Link
              href="/about#track-record"
              className="inline-flex items-center text-blue-600 hover:text-blue-700"
            >
              Discover Our Full Track Record
              <ArrowRight className="ml-2 h-5 w-5" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}