import React from 'react';
import { Train } from 'lucide-react';
import { Link } from '../../layout/Link';
import { AnimatedBackground } from '../../common/backgrounds/AnimatedBackground';
import { SlideIn } from '../../common/animations/SlideIn';
import { FadeIn } from '../../common/animations/FadeIn';

export function SentryHero() {
  return (
    <div className="relative min-h-[80vh] pt-24">
      {/* Animated Background */}
      <AnimatedBackground 
        videoUrl="https://cdn.coverr.co/videos/coverr-train-passing-by-at-night-4366/preview"
        fallbackImageUrl="https://images.unsplash.com/photo-1474487548417-781cb71495f3?auto=format&fit=crop&q=80"
        overlayOpacity={0.85}
      />
      
      {/* Content */}
      <div className="relative container mx-auto px-4 py-20">
        <div className="max-w-3xl">
          <SlideIn direction="down" delay={200}>
            <div className="flex items-center space-x-4 mb-6">
              <Train className="w-12 h-12 text-white" />
              <h1 className="text-4xl sm:text-5xl font-bold text-white">
                Sentry
              </h1>
            </div>
          </SlideIn>
          
          <FadeIn delay={600}>
            <p className="text-3xl font-bold text-white mb-6">
              Next-Generation Rail Infrastructure Monitoring
            </p>
            <p className="text-xl text-gray-300 mb-8">
              Revolutionizing rail infrastructure monitoring by combining advanced Distributed Acoustic 
              Sensing (DAS), drone technology, and AI-driven analytics for enhanced safety and 
              operational efficiency.
            </p>
          </FadeIn>
          
          <SlideIn direction="up" delay={800}>
            <div className="flex flex-wrap gap-4">
              <Link
                href="/contact"
                className="inline-flex items-center px-6 py-3 rounded-lg bg-blue-600 hover:bg-blue-700 transition-colors hover-lift"
              >
                Contact Us to Learn More
              </Link>
              <Link
                href="/contact?demo=sentry"
                className="inline-flex items-center px-6 py-3 rounded-lg bg-white/10 hover:bg-white/20 transition-colors hover-lift"
              >
                Request a Demo
              </Link>
            </div>
          </SlideIn>
        </div>
      </div>
    </div>
  );
}