import React, { useState, useEffect } from 'react';

interface AnimatedBackgroundProps {
  videoUrl?: string;
  fallbackImageUrl: string;
  overlayOpacity?: number;
}

export function AnimatedBackground({ 
  videoUrl,
  fallbackImageUrl,
  overlayOpacity = 0.85
}: AnimatedBackgroundProps) {
  const [videoError, setVideoError] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  // Reliable video URL from a CDN
  const defaultVideoUrl = 'https://cdn.coverr.co/videos/coverr-digital-network-connections-1009/preview';

  useEffect(() => {
    // Reset states when video URL changes
    setVideoError(false);
    setIsVideoLoaded(false);
  }, [videoUrl]);

  return (
    <>
      {/* Video Background */}
      {!videoError && (
        <div className="absolute inset-0 overflow-hidden bg-gray-900">
          <video
            autoPlay
            loop
            muted
            playsInline
            className={`absolute w-full h-full object-cover transition-opacity duration-700 ${
              isVideoLoaded ? 'opacity-100' : 'opacity-0'
            }`}
            poster={fallbackImageUrl}
            onLoadedData={() => setIsVideoLoaded(true)}
            onError={() => setVideoError(true)}
          >
            <source src={videoUrl || defaultVideoUrl} type="video/mp4" />
          </video>
        </div>
      )}

      {/* Fallback Image */}
      <div 
        className={`absolute inset-0 bg-cover bg-center transition-opacity duration-700 ${
          isVideoLoaded && !videoError ? 'opacity-0' : 'opacity-100'
        }`}
        style={{ backgroundImage: `url(${fallbackImageUrl})` }}
      />

      {/* Gradient Overlay */}
      <div 
        className="absolute inset-0 bg-gradient-to-br from-gray-900 via-gray-800 to-blue-900"
        style={{ opacity: overlayOpacity }}
      />

      {/* Animated Grid Pattern */}
      <div className="absolute inset-0">
        <div className="w-full h-full grid-animation opacity-30" />
      </div>
    </>
  );
}