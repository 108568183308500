import React from 'react';
import { usePageTransition } from '../../../hooks/usePageTransition';

interface PageTransitionProps {
  children: React.ReactNode;
}

export function PageTransition({ children }: PageTransitionProps) {
  usePageTransition();

  return (
    <div className="transition-all duration-300 ease-in-out">
      {children}
    </div>
  );
}