import React from 'react';
import { Video } from '../../../common/video/Video';

export function RealTimeUpdatesSection() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
            <div className="order-2 md:order-1">
              <div className="rounded-xl overflow-hidden shadow-lg">
                <Video
                  src="https://firebasestorage.googleapis.com/v0/b/proaptus-website.firebasestorage.app/o/Insta%209.mp4?alt=media&token=079d8273-8c60-4817-ad7c-417ed2eb3e56"
                  fallbackImage="https://images.unsplash.com/photo-1494522358652-f30e61a60313?auto=format&fit=crop&q=80"
                  className="w-full aspect-video object-cover"
                />
              </div>
            </div>
            <div className="order-1 md:order-2">
              <h2 className="text-3xl font-bold mb-6">Real-Time Updates</h2>
              <p className="text-xl text-gray-600 mb-8">
                Stay informed with instant updates and proactive notifications that 
                keep your journey on track and stress-free.
              </p>
              <ul className="space-y-4 text-gray-600">
                <li className="flex items-start">
                  <span className="w-2 h-2 mt-2 rounded-full bg-blue-600 mr-3" />
                  <span>Live service disruption alerts</span>
                </li>
                <li className="flex items-start">
                  <span className="w-2 h-2 mt-2 rounded-full bg-blue-600 mr-3" />
                  <span>Dynamic route adjustments</span>
                </li>
                <li className="flex items-start">
                  <span className="w-2 h-2 mt-2 rounded-full bg-blue-600 mr-3" />
                  <span>Proactive travel recommendations</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}