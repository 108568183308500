import React from 'react';
import { Mail } from 'lucide-react';

export function TermsFooter() {
  return (
    <div className="p-8 border-t bg-gray-50 rounded-b-xl">
      <div className="space-y-6">
        <div>
          <h2 className="text-xl font-semibold mb-3">Contact Us</h2>
          <p className="text-gray-600">
            If you have any questions regarding these Terms, please contact us at:
          </p>
          <a 
            href="mailto:legal@proaptus.com"
            className="inline-flex items-center text-blue-600 hover:text-blue-700 mt-2"
          >
            <Mail className="w-4 h-4 mr-2" />
            legal@proaptus.com
          </a>
        </div>

        <p className="text-sm text-gray-500 italic">
          This document is for informational purposes and does not constitute legal advice.
          Consult a qualified attorney for legal advice specific to your needs.
        </p>
      </div>
    </div>
  );
}