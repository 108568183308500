import React from 'react';
import { BusReformHero } from '../../components/solutions/bus-reform/BusReformHero';
import { KeyCapabilities } from '../../components/solutions/bus-reform/KeyCapabilities';
import { NetworkMonitoringSection } from '../../components/solutions/bus-reform/video-sections/NetworkMonitoringSection';
import { ServiceOptimizationSection } from '../../components/solutions/bus-reform/video-sections/ServiceOptimizationSection';
import { SuccessStory } from '../../components/solutions/bus-reform/SuccessStory';
import { ApproachSection } from '../../components/solutions/bus-reform/ApproachSection';
import { Benefits } from '../../components/solutions/bus-reform/Benefits';
import { CallToAction } from '../../components/solutions/bus-reform/CallToAction';

export function BusReform() {
  return (
    <div>
      <BusReformHero />
      <KeyCapabilities />
      <NetworkMonitoringSection />
      <ServiceOptimizationSection />
      <SuccessStory />
      <ApproachSection />
      <Benefits />
      <CallToAction />
    </div>
  );
}