import React from 'react';
import { Brain, Code, Cpu } from 'lucide-react';
import { TeamMember } from './TeamMember';

const teamMembers = [
  {
    name: "Anitha Kugur",
    role: "AI Engineer",
    description: "Specializes in predictive analytics and anomaly detection, driving innovation in our AI systems.",
    icon: Brain,
    linkedIn: "https://www.linkedin.com/in/anithakugur21/"
  },
  {
    name: "Abdurrahman Shiyanbola",
    role: "AI Developer",
    description: "Expert in DAS model refinement, enhancing our sensing capabilities.",
    icon: Code,
    linkedIn: "https://www.linkedin.com/company/proaptus/"
  },
  {
    name: "Yash Kulthe",
    role: "AI Deployment Engineer",
    description: "Leads drone integration and system scalability initiatives.",
    icon: Cpu,
    linkedIn: "https://www.linkedin.com/in/yash-kulthe/"
  }
];

export function CoreTeamSection() {
  return (
    <div>
      <h3 className="text-2xl font-bold text-center mb-12">Core Team</h3>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {teamMembers.map((member) => (
          <TeamMember key={member.name} {...member} />
        ))}
      </div>
    </div>
  );
}