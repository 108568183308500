import React from 'react';
import { MaxWidthContainer } from '../../common/layout/MaxWidthContainer';
import { ArticleHeader } from './ArticleHeader';
import { ArticleContent } from './ArticleContent';
import { ArticleFooter } from './ArticleFooter';
import { ArticleNavigation } from './ArticleNavigation';
import { useArticleNavigation } from '../../../hooks/useArticleNavigation';

interface ArticleProps {
  article: {
    id: string;
    title: string;
    date: string;
    author: string;
    category: string;
    excerpt: string;
    content: string;
    imageUrl?: string;
    publicationInfo?: React.ReactNode;
  };
}

export function ArticleLayout({ article }: ArticleProps) {
  const { previous, next } = useArticleNavigation(article.id);

  return (
    <article className="min-h-screen bg-white pt-24">
      <MaxWidthContainer>
        <div className="max-w-3xl mx-auto">
          <ArticleHeader 
            title={article.title}
            publicationInfo={article.publicationInfo}
            imageUrl={article.imageUrl}
          />
          <ArticleContent content={article.content} />
          <ArticleNavigation previous={previous} next={next} />
          <ArticleFooter />
        </div>
      </MaxWidthContainer>
    </article>
  );
}