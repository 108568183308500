import React from 'react';
import { Trophy, Leaf, Zap, Gauge, Shield, LineChart } from 'lucide-react';

const metrics = [
  {
    icon: Leaf,
    label: 'Hardware Footprint',
    value: 'Minimal',
    description: 'Utilizes existing fiber infrastructure'
  },
  {
    icon: Zap,
    label: 'Energy Efficiency',
    value: 'High',
    description: 'Low power consumption'
  },
  {
    icon: Shield,
    label: 'Operational Impact',
    value: 'Low',
    description: 'Reduced field interventions'
  },
  {
    icon: Gauge,
    label: 'Scalability',
    value: 'High',
    description: 'Easy network expansion'
  },
  {
    icon: LineChart,
    label: 'Sustainability',
    value: 'High',
    description: 'Minimal environmental impact'
  }
];

const solutions = [
  { 
    name: 'Traditional Sensor Arrays',
    hardware: 'High',
    energy: 'Medium-High',
    carbon: 'Medium-High',
    operational: 'Medium',
    sustainability: 'Moderate'
  },
  { 
    name: 'Camera-Centric Systems',
    hardware: 'High',
    energy: 'High',
    carbon: 'High',
    operational: 'High',
    sustainability: 'Lower'
  },
  { 
    name: 'Drone-Based Monitoring',
    hardware: 'Medium',
    energy: 'Medium',
    carbon: 'Medium',
    operational: 'Medium-High',
    sustainability: 'Moderate'
  },
  { 
    name: 'Lighthouse (DAS + DTS)',
    hardware: 'Low',
    energy: 'Low',
    carbon: 'Low',
    operational: 'Low',
    sustainability: 'High'
  }
];

// Helper function to determine if a value is the best
const isBestValue = (value: string, category: string) => {
  // For hardware, energy, carbon, and operational categories, "Low" is best
  if (['hardware', 'energy', 'carbon', 'operational'].includes(category)) {
    return value === 'Low';
  }
  // For sustainability, "High" is best
  if (category === 'sustainability') {
    return value === 'High';
  }
  return false;
};

export function ComparisonSection() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto">
          <div className="text-center mb-16">
            <Trophy className="w-12 h-12 text-blue-600 mx-auto mb-4" />
            <h2 className="text-3xl font-bold mb-4">Environmental Performance</h2>
            <p className="text-xl text-gray-600">
              Lighthouse leads the industry in sustainability and resource efficiency
            </p>
          </div>

          {/* Key Metrics Grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6 mb-16">
            {metrics.map((metric) => {
              const Icon = metric.icon;
              return (
                <div key={metric.label} className="bg-white p-6 rounded-xl shadow-sm text-center">
                  <Icon className="w-8 h-8 text-blue-600 mx-auto mb-4" />
                  <h3 className="text-lg font-semibold mb-2">{metric.label}</h3>
                  <p className="text-3xl font-bold text-blue-600 mb-2">{metric.value}</p>
                  <p className="text-sm text-gray-600">{metric.description}</p>
                </div>
              );
            })}
          </div>

          {/* Comparison Table */}
          <div className="bg-white rounded-xl shadow-sm overflow-hidden">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-50">
                    <th className="px-6 py-4 text-left text-sm font-semibold text-gray-900">Solution</th>
                    <th className="px-6 py-4 text-center text-sm font-semibold text-gray-900">Hardware Requirement</th>
                    <th className="px-6 py-4 text-center text-sm font-semibold text-gray-900">Energy Consumption</th>
                    <th className="px-6 py-4 text-center text-sm font-semibold text-gray-900">Carbon Impact</th>
                    <th className="px-6 py-4 text-center text-sm font-semibold text-gray-900">Operational Overhead</th>
                    <th className="px-6 py-4 text-center text-sm font-semibold text-gray-900">Overall Sustainability</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {solutions.map((solution) => (
                    <tr 
                      key={solution.name}
                      className={solution.name === 'Lighthouse (DAS + DTS)' ? 'bg-blue-50' : ''}
                    >
                      <td className="px-6 py-4 text-sm font-medium text-gray-900">{solution.name}</td>
                      <td className={`px-6 py-4 text-sm text-center ${
                        isBestValue(solution.hardware, 'hardware') ? 'font-bold text-blue-600' : 'text-gray-600'
                      }`}>{solution.hardware}</td>
                      <td className={`px-6 py-4 text-sm text-center ${
                        isBestValue(solution.energy, 'energy') ? 'font-bold text-blue-600' : 'text-gray-600'
                      }`}>{solution.energy}</td>
                      <td className={`px-6 py-4 text-sm text-center ${
                        isBestValue(solution.carbon, 'carbon') ? 'font-bold text-blue-600' : 'text-gray-600'
                      }`}>{solution.carbon}</td>
                      <td className={`px-6 py-4 text-sm text-center ${
                        isBestValue(solution.operational, 'operational') ? 'font-bold text-blue-600' : 'text-gray-600'
                      }`}>{solution.operational}</td>
                      <td className={`px-6 py-4 text-sm text-center ${
                        isBestValue(solution.sustainability, 'sustainability') ? 'font-bold text-blue-600' : 'text-gray-600'
                      }`}>{solution.sustainability}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <p className="text-sm text-gray-500 mt-4 text-center">
            *Based on comparative analysis of monitoring solutions for urban infrastructure
          </p>
        </div>
      </div>
    </section>
  );
}