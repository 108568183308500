import React from 'react';
import { Building2, Search, FileCheck } from 'lucide-react';
import { InspectionFeature } from './InspectionFeature';

const features = [
  {
    icon: Building2,
    title: 'Infrastructure Analysis',
    description: 'Comprehensive assessment of structural integrity using advanced imaging and multi-sensor data fusion'
  },
  {
    icon: Search,
    title: 'Anomaly Detection',
    description: 'AI-powered identification of structural defects, wear patterns, and potential safety concerns'
  },
  {
    icon: FileCheck,
    title: 'Automated Reporting',
    description: 'Generation of detailed inspection reports with maintenance recommendations and compliance documentation'
  }
];

export function IntelligentInspection() {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-3xl font-bold mb-6">Intelligent Inspections</h2>
              <p className="text-xl text-gray-600 mb-8">
                Our advanced drone system combines AI-powered visual analysis with multi-sensor 
                data to deliver comprehensive infrastructure monitoring and predictive maintenance insights.
              </p>
              
              <div className="space-y-6">
                {features.map((feature) => (
                  <InspectionFeature
                    key={feature.title}
                    icon={feature.icon}
                    title={feature.title}
                    description={feature.description}
                  />
                ))}
              </div>
            </div>
            
            <div className="relative">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/proaptus-website.firebasestorage.app/o/DALL%C2%B7E%202025-01-01%2011.31.49%20-%20A%20realistic%20depiction%20of%20a%20high-tech%20inspection%20drone%20examining%20a%20railway%20bridge%20in%20excellent%20condition%20with%20a%20train%20passing%20over%20it.%20The%20bridge%20is%20mo.webp?alt=media&token=2e87ce4f-15d5-4914-9f58-1a516ef7b0f8"
                alt="Drone performing infrastructure inspection"
                className="rounded-xl shadow-lg w-full"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900/20 to-transparent rounded-xl" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}