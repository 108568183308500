import React from 'react';
import { Header } from './header/Header';
import { Footer } from './footer/Footer';
import { HubspotTracking } from '../common/tracking/HubspotTracking';
import { SkipLink } from './SkipLink';
import { useScrollRestoration } from '../../hooks/useScrollRestoration';

interface AppLayoutProps {
  children: React.ReactNode;
}

export function AppLayout({ children }: AppLayoutProps) {
  useScrollRestoration();

  return (
    <div className="min-h-screen flex flex-col">
      <HubspotTracking />
      <SkipLink />
      <Header />
      <main id="main-content" className="flex-grow" role="main">
        {children}
      </main>
      <Footer />
    </div>
  );
}