import type { NewsArticle } from '../types/news';

// Articles sorted by date (newest first)
export const articles: NewsArticle[] = [
  {
    id: 'proaptus-innovators-2024',
    title: "Proaptus Wins New Innovators 2024 & Showcases in Japan",
    excerpt: "Discover how Proaptus, winner of the New Innovators in Communication Networks 2024 competition, is accelerating AI-driven telecom innovation in Japan.",
    imageUrl: "https://images.unsplash.com/photo-1536098561742-ca998e48cbcc?auto=format&fit=crop&q=80",
    date: "2024-12-04",
    author: "Proaptus Team",
    category: "ai"
  },
  {
    id: 'future-of-travel',
    title: "The Future of Travel: How Multi-Agent AI Will Transform Transportation",
    excerpt: "Explore how multi-agent AI systems could transform transportation by improving service responsiveness, customer experience, and operational resilience.",
    imageUrl: "https://images.unsplash.com/photo-1494522358652-f30e61a60313?auto=format&fit=crop&q=80",
    date: "2024-10-25",
    author: "Novae Travel Team",
    category: "ai"
  },
  {
    id: 'beyond-ai-assistants',
    title: "Beyond AI Assistants: Novae.Travel's Multi-Agent System",
    excerpt: "Discover how Novae.Travel's multi-agent system redefines travel planning with specialized AI agents for flights, hotels, tours, and more.",
    imageUrl: "https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80",
    date: "2024-10-04",
    author: "Novae Travel Team",
    category: "ai"
  },
  {
    id: 'transport-franchising',
    title: "AI Data Platforms Revolutionizing Transport Franchising",
    excerpt: "Explore how AI data platforms are transforming transport franchising, boosting efficiency, safety, and adaptability across various modes.",
    imageUrl: "https://images.unsplash.com/photo-1508720666804-4253e0d846f5?auto=format&fit=crop&q=80",
    date: "2024-08-14",
    author: "Novae Travel Team",
    category: "transport"
  },
  {
    id: 'ai-public-bus',
    title: "How AI Is Transforming Public Bus Transportation",
    excerpt: "Explore key AI applications in public bus services, from vehicle tracking to customer support and autonomous buses.",
    imageUrl: "https://images.unsplash.com/photo-1570125909232-eb263c188f7e?auto=format&fit=crop&q=80",
    date: "2024-06-17",
    author: "Proaptus Team",
    category: "transport"
  },
  {
    id: 'ai-accessibility',
    title: "How AI Is Enhancing Accessibility",
    excerpt: "Discover how AI technologies like screen readers, speech recognition, and sign language detection empower individuals with disabilities.",
    imageUrl: "https://images.unsplash.com/photo-1573497620053-ea5300f94f21?auto=format&fit=crop&q=80",
    date: "2024-03-15",
    author: "Proaptus Team",
    category: "ai"
  }
];