import React from 'react';
import { LoadingSpinner } from './LoadingSpinner';

interface LoadingScreenProps {
  message?: string;
  ariaLabel?: string;
}

export function LoadingScreen({ 
  message = 'Loading...', 
  ariaLabel = 'Content is loading'
}: LoadingScreenProps) {
  return (
    <div 
      className="fixed inset-0 z-50 bg-white/80 backdrop-blur-sm"
      role="progressbar"
      aria-label={ariaLabel}
      aria-busy="true"
    >
      <div className="relative z-10 h-full flex flex-col items-center justify-center">
        <LoadingSpinner />
        <p className="mt-4 text-lg text-gray-600">{message}</p>
      </div>
    </div>
  );
}