import React from 'react';
import { Mail } from 'lucide-react';
import { AnimatedBackground } from '../common/backgrounds/AnimatedBackground';
import { SlideIn } from '../common/animations/SlideIn';
import { FadeIn } from '../common/animations/FadeIn';
import { MaxWidthContainer } from '../common/layout/MaxWidthContainer';

export function ContactHero() {
  return (
    <div className="relative min-h-[50vh] pt-24">
      <AnimatedBackground 
        videoUrl="https://cdn.coverr.co/videos/coverr-typing-on-computer-keyboard-1584/preview"
        fallbackImageUrl="https://images.unsplash.com/photo-1423666639041-f56000c27a9a?auto=format&fit=crop&q=80"
        overlayOpacity={0.85}
      />
      
      <MaxWidthContainer className="relative py-20">
        <div className="max-w-3xl">
          <SlideIn direction="down" delay={200}>
            <div className="flex items-center space-x-4 mb-6">
              <Mail className="w-12 h-12 text-white" />
              <h1 className="text-4xl sm:text-5xl font-bold text-white">
                Get in Touch with Proaptus
              </h1>
            </div>
          </SlideIn>
          
          <FadeIn delay={600}>
            <p className="text-xl text-gray-300">
              Have questions or need assistance? We'd love to hear from you. Our team is ready to help you transform your infrastructure and transportation systems.
            </p>
          </FadeIn>
        </div>
      </MaxWidthContainer>
    </div>
  );
}