import React from 'react';
import { Icon } from '@iconify/react';

interface TechLogoProps {
  name: string;
  icon?: string;
  customImage?: string;
  className?: string;
}

export function TechLogo({ name, icon, customImage, className = 'w-16 h-16' }: TechLogoProps) {
  return (
    <div 
      className="group relative bg-transparent p-4 rounded-lg hover:bg-white/50 
                 transition-all duration-300 transform hover:scale-105"
    >
      <div className="aspect-square flex items-center justify-center">
        {customImage ? (
          <img 
            src={customImage}
            alt={`${name} logo`}
            className={`object-contain ${className}`}
          />
        ) : icon && (
          <Icon 
            icon={icon}
            className={className}
            style={{ color: 'currentColor' }}
            aria-label={`${name} logo`}
          />
        )}
      </div>
      <div className="absolute inset-0 flex items-center justify-center bg-black/75 
                      opacity-0 group-hover:opacity-100 transition-opacity rounded-lg">
        <span className="text-white text-sm font-medium text-center px-2">{name}</span>
      </div>
    </div>
  );
}