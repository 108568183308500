import React from 'react';
import { Activity, Network, Shield } from 'lucide-react';

const useCases = [
  {
    icon: Activity,
    title: 'Micro-Seismic Monitoring',
    description: 'Detect subtle seismic activity that could affect urban infrastructure.',
  },
  {
    icon: Network,
    title: 'Fibre and Duct Monitoring',
    description: 'Track physical conditions in ducts to ensure optimal performance of communication networks.',
  },
  {
    icon: Shield,
    title: 'Real-Time Surveillance',
    description: 'Support monitoring for large-scale events or emergencies, ensuring public safety.',
  },
];

export function SpecialistUseCases() {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-16">Specialist Use Cases</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto">
          {useCases.map((useCase) => {
            const Icon = useCase.icon;
            return (
              <div key={useCase.title} className="bg-gray-50 p-6 rounded-lg">
                <Icon className="w-8 h-8 text-blue-600 mb-4" />
                <h3 className="text-lg font-semibold mb-3">{useCase.title}</h3>
                <p className="text-gray-600">{useCase.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}