import { useState, useEffect, useCallback } from 'react';
import { getPage } from '../utils/routing';

export function useRouting() {
  const [page, setPage] = useState(() => {
    // Get initial page based on current URL
    const path = window.location.pathname;
    const hash = window.location.hash;
    return getPage(path, hash);
  });
  const [key, setKey] = useState(window.location.pathname + window.location.hash);

  const handleNavigation = useCallback(() => {
    const path = window.location.pathname;
    const hash = window.location.hash;
    
    // Update page and key
    setKey(path + hash);
    setPage(getPage(path, hash));
    
    // Scroll to top unless there's a hash
    if (!hash) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    // Handle browser back/forward
    window.addEventListener('popstate', handleNavigation);
    
    // Handle link clicks
    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const link = target.closest('a');
      
      if (link && 
          link.href && 
          link.origin === window.location.origin && 
          !link.hasAttribute('download') && 
          !link.hasAttribute('target')) {
        e.preventDefault();
        const newPath = link.pathname + link.hash;
        
        // Only push state if path is different
        if (newPath !== window.location.pathname + window.location.hash) {
          window.history.pushState({}, '', newPath);
          handleNavigation();
        }
      }
    };

    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('popstate', handleNavigation);
      window.removeEventListener('click', handleClick);
    };
  }, [handleNavigation]);

  return { page, key };
}