import React from 'react';
import { Users } from 'lucide-react';
import { TeamMember } from './TeamMember';

const leadership = {
  name: "Chinedu Achara",
  role: "Founder & CEO",
  description: "22+ years in AI and telecommunications. Developed the Agentic Framework powering solutions like Sentry and Novae.Travel. Led digital transformation for the Bee Network.",
  icon: Users,
  imageSrc: "/images/team/chinedu-achara.png",
  linkedIn: "https://www.linkedin.com/in/proaptus/",
  qrProfile: "https://proaptus.co.uk/chinedu-achara"
};

export function LeadershipSection() {
  return (
    <div className="mb-20">
      <h3 className="text-2xl font-bold text-center mb-12">Leadership</h3>
      <div className="max-w-2xl mx-auto bg-gradient-to-br from-blue-50 to-white p-8 rounded-2xl">
        <TeamMember {...leadership} size="large" />
      </div>
    </div>
  );
}