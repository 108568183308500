import React from 'react';
import { Check } from 'lucide-react';

const advantages = [
  {
    title: 'Inclusivity',
    description: 'Designed to address the needs of all travelers, especially those with accessibility challenges.',
  },
  {
    title: 'Proactive Assistance',
    description: 'Real-time updates minimize disruptions and keep travelers informed throughout their journey.',
  },
  {
    title: 'Enhanced Communication',
    description: 'Enables seamless interaction between travelers and service providers for better support.',
  },
  {
    title: 'Scalability',
    description: 'Adaptable to transport networks of varying complexities and sizes worldwide.',
  },
];

export function Advantages() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-16">Key Advantages</h2>
        <div className="max-w-4xl mx-auto grid grid-cols-1 gap-6">
          {advantages.map((advantage) => (
            <div key={advantage.title} className="flex items-start space-x-4">
              <div className="flex-shrink-0">
                <Check className="w-6 h-6 text-blue-600" />
              </div>
              <div>
                <h3 className="text-lg font-semibold mb-2">{advantage.title}</h3>
                <p className="text-gray-600">{advantage.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}