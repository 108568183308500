import React from 'react';

interface TermsContentProps {
  title: string;
  content: string;
}

export function TermsContent({ title, content }: TermsContentProps) {
  return (
    <div>
      <h2 className="text-xl font-semibold mb-3">{title}</h2>
      <p className="text-gray-600 leading-relaxed">{content}</p>
    </div>
  );
}