import React from 'react';

interface ArticleContentProps {
  content: string;
}

export function ArticleContent({ content }: ArticleContentProps) {
  return (
    <div className="prose prose-lg max-w-none">
      <div 
        dangerouslySetInnerHTML={{ __html: content }}
        className="article-content"
      />
    </div>
  );
}