import React from 'react';
import { TimelineEvent } from './TimelineEvent';
import { History } from 'lucide-react';

const events = [
  {
    year: '2018',
    title: 'Proaptus Founded',
    description: 'Established to transform critical infrastructure through innovation in AI, fibre optic sensing, and digital transformation.',
    imageSrc: 'https://images.unsplash.com/photo-1581091226825-a6a2a5aee158?auto=format&fit=crop&q=80'
  },
  {
    year: '2019',
    title: 'Multiple Satellite & Digital Transformation Projects',
    description: 'Successfully delivered large-scale technology programs for telecom and transport clients.',
    imageSrc: 'https://images.unsplash.com/photo-1516849841032-87cbac4d88f7?auto=format&fit=crop&q=80'
  },
  {
    year: '2019–2020',
    title: 'Launch Readiness for £895M Project',
    description: 'Played a key role in launch readiness for BT and the Home Office on a high-value £895M project.',
    imageSrc: 'https://images.unsplash.com/photo-1612574935301-af13ccce9258?auto=format&fit=crop&q=80'
  },
  {
    year: '2021–2022',
    title: '£7M Digital Platforms for Telecoms Operator',
    description: 'Delivered scalable, reliable platforms for a leading telecom provider, enhancing satellite communication for emergency services.',
    imageSrc: 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80'
  },
  {
    year: '2022–2024',
    title: 'Complex £80M Public Transport Technology Transformation',
    description: 'Spearheaded the technology for Greater Manchester\'s bus franchising program, introducing real-time KPI tracking, automated compliance monitoring, and modular systems.',
    imageSrc: 'https://images.unsplash.com/photo-1508720666804-4253e0d846f5?auto=format&fit=crop&q=80'
  },
  {
    year: 'September 2024',
    title: 'Grant Secured for Novae.Lighthouse',
    description: 'Awarded Innovate UK funding to advance Lighthouse\'s real-time traffic flow and anomaly detection capabilities.',
    imageSrc: 'https://images.unsplash.com/photo-1573108724029-4c46571d6490?auto=format&fit=crop&q=80'
  },
];

export function Timeline() {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <History className="w-12 h-12 text-blue-600 mx-auto mb-4" />
          <h2 className="text-3xl font-bold mb-4">Timeline of Achievements</h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Our journey of innovation and excellence in transforming infrastructure and transportation.
          </p>
        </div>
        <div className="max-w-3xl mx-auto">
          {events.map((event) => (
            <TimelineEvent key={event.year} {...event} />
          ))}
        </div>
      </div>
    </section>
  );
}