import React from 'react';
import { ChevronDown } from 'lucide-react';
import { Link } from '../../Link';
import { navigationItems } from './navigationItems';

export function NavigationDesktop() {
  return (
    <div className="hidden md:flex items-center space-x-8">
      {navigationItems.map((item) => (
        <div key={item.name} className="relative group">
          {item.submenu ? (
            <button className="flex items-center space-x-1 text-gray-600 hover:text-brand-navy">
              <span>{item.name}</span>
              <ChevronDown className="w-4 h-4" />
            </button>
          ) : (
            <Link href={item.href} className="text-gray-600 hover:text-brand-navy">
              {item.name}
            </Link>
          )}

          {item.submenu && (
            <div className="absolute top-full left-0 w-48 py-2 bg-white rounded-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200">
              {item.submenu.map((subitem) => (
                <Link
                  key={subitem.name}
                  href={subitem.href}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                >
                  {subitem.name}
                </Link>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}