import React from 'react';
import { Achievements } from './about/Achievements';
import { Certifications } from './about/Certifications';
import { Mission } from './about/Mission';

export function AboutSection() {
  return (
    <section className="bg-white rounded-xl shadow-sm p-8">
      <h2 className="text-2xl font-bold mb-6">About Me</h2>
      
      <div className="space-y-6">
        <p className="text-gray-600">
          As the Founder and CEO of Proaptus, I lead a dedicated team specializing in AI-powered 
          solutions for transportation and infrastructure sectors. My expertise spans fibre optic 
          sensing, agentic AI, and project management for large-scale digital transformations.
        </p>

        <Achievements />
        <Certifications />
        <Mission />
      </div>
    </section>
  );
}