import React from 'react';
import { Brain, Scale, Users, Award } from 'lucide-react';

const reasons = [
  {
    icon: Brain,
    title: 'AI & Fibre-Optic Expertise',
    description: 'Industry-leading expertise in AI, machine learning, and fibre optic sensing technology.',
  },
  {
    icon: Award,
    title: 'Proven Track Record',
    description: 'Successful implementations including the Bee Network transformation and Innovate UK projects.',
  },
  {
    icon: Scale,
    title: 'Tailored, Scalable Solutions',
    description: 'Custom solutions that grow with your needs while maintaining optimal performance.',
  },
  {
    icon: Users,
    title: 'Sustainability & Accessibility',
    description: 'Committed to environmental sustainability and inclusive transportation solutions.',
  },
];

export function WhyChooseUs() {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Why Choose Proaptus?</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {reasons.map((reason) => {
            const Icon = reason.icon;
            return (
              <div key={reason.title} className="bg-gray-50 p-6 rounded-lg">
                <Icon className="w-10 h-10 text-blue-600 mb-4" />
                <h3 className="text-xl font-semibold mb-2">{reason.title}</h3>
                <p className="text-gray-600">{reason.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}