import React from 'react';

export function Certifications() {
  return (
    <div>
      <h3 className="font-semibold mb-3">Certifications</h3>
      <p className="text-gray-600">
        PMP, PRINCE2, Agile Programme Management, and AWS Cloud Practitioner.
      </p>
    </div>
  );
}