import React from 'react';
import { CookieContent } from './CookieContent';

const sections = [
  {
    title: '1. What are Cookies?',
    content: [
      {
        text: 'Cookies are small text files stored on your device when you visit websites. They serve several purposes:',
        items: [
          'Help websites remember your preferences',
          'Enable certain website functionalities',
          'Provide information about how visitors use the site',
          'Support security features'
        ]
      }
    ]
  },
  {
    title: '2. Types of Cookies We Use',
    content: [
      {
        subtitle: 'Essential Cookies',
        text: 'Required for basic site functionality:',
        items: [
          'Authentication and security',
          'Session management',
          'Remember your preferences'
        ]
      },
      {
        subtitle: 'Analytics Cookies',
        text: 'Help us understand how visitors interact with our website:',
        items: [
          'Track page views and navigation',
          'Analyze user behavior',
          'Improve website performance'
        ]
      },
      {
        subtitle: 'Functional Cookies',
        text: 'Enable enhanced functionality:',
        items: [
          'Remember language preferences',
          'Store technical information',
          'Provide personalized features'
        ]
      }
    ]
  },
  {
    title: '3. Managing Cookies',
    content: [
      {
        text: 'You have control over cookies through your browser settings:',
        items: [
          'Block or delete cookies',
          'Accept or reject new cookies',
          'Set preferences for certain websites'
        ]
      },
      {
        text: 'Please note: Blocking essential cookies may affect site functionality.'
      }
    ]
  },
  {
    title: '4. Third-Party Cookies',
    content: [
      {
        text: 'Some cookies are placed by third-party services:',
        items: [
          'Analytics providers (e.g., Google Analytics)',
          'Social media platforms',
          'External content providers'
        ]
      }
    ]
  }
];

export function CookieSection() {
  return (
    <div className="p-8">
      <div className="space-y-8">
        {sections.map((section) => (
          <CookieContent
            key={section.title}
            title={section.title}
            content={section.content}
          />
        ))}
      </div>
    </div>
  );
}