import React from 'react';
import { Link } from '../layout/Link';
import { Train, Wifi, Plane, Bus } from 'lucide-react';
import { AnimateOnScroll } from '../common/animations/AnimateOnScroll';
import { StaggerChildren } from '../common/animations/StaggerChildren';

const solutions = [
  {
    name: 'Sentry',
    description: 'Advanced rail infrastructure monitoring and proactive safety through AI-powered analytics.',
    icon: Train,
    href: '/solutions/sentry',
  },
  {
    name: 'Lighthouse',
    description: 'Real-time traffic flow monitoring and infrastructure anomaly detection using fibre optic sensing.',
    icon: Wifi,
    href: '/solutions/lighthouse',
  },
  {
    name: 'Novae.Travel',
    description: 'AI-powered, inclusive travel support platform making transportation accessible for everyone.',
    icon: Plane,
    href: '/solutions/novae-travel',
  },
  {
    name: 'Bus Reform',
    description: 'Transforming public transport through technology-driven franchising solutions and real-time monitoring.',
    icon: Bus,
    href: '/solutions/bus-reform',
  },
];

export function Solutions() {
  return (
    <section id="solutions" className="py-20 bg-white scroll-mt-16">
      <div className="container mx-auto px-4">
        <AnimateOnScroll animation="animate-slide-up">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Our Solutions
            </h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Discover how our AI-driven solutions are transforming infrastructure 
              and transportation across industries.
            </p>
          </div>
        </AnimateOnScroll>

        <StaggerChildren 
          animation="animate-fade-in"
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8"
        >
          {solutions.map((solution) => {
            const Icon = solution.icon;
            return (
              <Link
                key={solution.name}
                href={solution.href}
                className="group block p-8 bg-gray-50 rounded-xl hover-lift hover:bg-gray-100 transition-all duration-300"
              >
                <div className="mb-4">
                  <Icon className="w-12 h-12 text-blue-600 transition-transform duration-300 group-hover:scale-110" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  {solution.name}
                </h3>
                <p className="text-gray-600">
                  {solution.description}
                </p>
              </Link>
            );
          })}
        </StaggerChildren>
      </div>
    </section>
  );
}