import React from 'react';
import { AlertTriangle, RefreshCw } from 'lucide-react';

interface ErrorFallbackProps {
  error: Error | null;
  onReset: () => void;
}

export function ErrorFallback({ error, onReset }: ErrorFallbackProps) {
  return (
    <div role="alert" className="min-h-[400px] flex items-center justify-center p-8">
      <div className="max-w-lg w-full bg-red-50 p-6 rounded-lg">
        <div className="flex items-center mb-4">
          <AlertTriangle className="w-6 h-6 text-red-600 mr-2" />
          <h2 className="text-lg font-semibold text-red-700">
            Something went wrong
          </h2>
        </div>
        
        <p className="text-red-600 mb-6">
          {error?.message || 'An unexpected error occurred'}
        </p>
        
        <div className="flex justify-end">
          <button
            onClick={onReset}
            className="inline-flex items-center px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
          >
            <RefreshCw className="w-4 h-4 mr-2" />
            Try again
          </button>
        </div>
      </div>
    </div>
  );
}