import React from 'react';
import { Helmet } from 'react-helmet-async';

interface OrganizationSchema {
  type: 'Organization';
  name: string;
  url: string;
  logo: string;
  description: string;
  address: {
    streetAddress: string;
    addressLocality: string;
    postalCode: string;
    addressCountry: string;
  };
  contactPoint: {
    telephone: string;
    email: string;
    contactType: string;
  };
}

interface FAQSchema {
  type: 'FAQPage';
  questions: Array<{
    question: string;
    answer: string;
  }>;
}

interface BreadcrumbSchema {
  type: 'BreadcrumbList';
  items: Array<{
    name: string;
    item: string;
  }>;
}

type SchemaType = OrganizationSchema | FAQSchema | BreadcrumbSchema;

interface SchemaMarkupProps {
  schema: SchemaType;
}

export function SchemaMarkup({ schema }: SchemaMarkupProps) {
  const baseSchema = {
    '@context': 'https://schema.org',
    '@type': schema.type,
    ...schema
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(baseSchema)}
      </script>
    </Helmet>
  );
}