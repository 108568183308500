import React from 'react';
import { CheckCircle, Clock, Users, LineChart } from 'lucide-react';

const outcomes = [
  {
    icon: CheckCircle,
    title: 'Successful Launch',
    description: 'Delivered Phase 1 and 2 on time and within budget.',
  },
  {
    icon: Clock,
    title: 'Efficiency Gains',
    description: 'Automated key processes reducing manual intervention.',
  },
  {
    icon: Users,
    title: 'Stakeholder Alignment',
    description: 'Unified multiple stakeholders under a common vision.',
  },
  {
    icon: LineChart,
    title: 'Performance Tracking',
    description: 'Real-time KPI monitoring and compliance reporting.',
  },
];

export function SuccessStory() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl font-bold text-center mb-8">Success Story: Bee Network</h2>
          
          <div className="bg-white p-8 rounded-xl shadow-sm mb-12">
            <h3 className="text-xl font-semibold mb-4">The Challenge</h3>
            <p className="text-gray-600 mb-6">
              Facilitating the transition from a deregulated to a franchised bus network 
              under tight timelines and evolving policies.
            </p>

            <h3 className="text-xl font-semibold mb-4">Our Approach</h3>
            <ul className="list-disc list-inside text-gray-600 mb-6 space-y-2">
              <li>Led the technology programme to ensure 'Day 1' operational readiness</li>
              <li>Integrated multi-vendor systems with modular architecture design</li>
              <li>Automated KPI reporting and compliance monitoring</li>
              <li>Developed operator onboarding tools and performance dashboards</li>
            </ul>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {outcomes.map((outcome) => {
              const Icon = outcome.icon;
              return (
                <div key={outcome.title} className="bg-white p-6 rounded-lg shadow-sm">
                  <div className="flex items-center mb-4">
                    <Icon className="w-6 h-6 text-blue-600 mr-3" />
                    <h3 className="text-lg font-semibold">{outcome.title}</h3>
                  </div>
                  <p className="text-gray-600">{outcome.description}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}