import { useEffect } from 'react';
import { useAnimation } from '../contexts/AnimationContext';

export function usePageTransition() {
  const { startTransition, endTransition } = useAnimation();

  useEffect(() => {
    // Start transition immediately
    startTransition();

    // End transition after a short delay
    const timer = setTimeout(() => {
      endTransition();
    }, 300);

    // Scroll to top
    window.scrollTo(0, 0);

    return () => clearTimeout(timer);
  }, [startTransition, endTransition]);
}