import React from 'react';
import type { LogoProps } from './types';

export function LogoFallback({ className = '', height = 48 }: LogoProps) {
  // Calculate width based on the original aspect ratio (2048:809)
  const aspectRatio = 2048 / 809;
  const width = height * aspectRatio;

  return (
    <div 
      className={`flex items-center justify-center ${className}`}
      style={{ 
        height: `${height}px`,
        width: `${width}px`
      }}
    >
      <span className="font-bold text-blue-600">Proaptus</span>
    </div>
  );
}