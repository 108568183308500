import React from 'react';
import { Trophy, BarChart2, Clock, DollarSign, Shield, Zap } from 'lucide-react';

const metrics = [
  {
    icon: BarChart2,
    label: 'Detection Accuracy',
    value: '94%',
    description: 'Highest accuracy rate among all solutions'
  },
  {
    icon: Clock,
    label: 'Response Time',
    value: '10 min',
    description: 'Fastest response time in the industry'
  },
  {
    icon: DollarSign,
    label: 'Cost Effective',
    value: '£2.2M',
    description: 'Low CAPEX with optimized OPEX'
  },
  {
    icon: Shield,
    label: 'Reliability',
    value: '99%',
    description: 'Industry-leading system reliability'
  },
  {
    icon: Zap,
    label: 'Disruption Reduction',
    value: '84%',
    description: 'Significant reduction in service disruptions'
  }
];

const solutions = [
  { name: 'DAS-Only', accuracy: 77, response: 66, capex: 2100, opex: 70, reliability: 91, reduction: 37 },
  { name: 'Camera-Based', accuracy: 66, response: 61, capex: 6100, opex: 880, reliability: 85, reduction: 46 },
  { name: 'Hybrid (Fence + Cam)', accuracy: 88, response: 51, capex: 6100, opex: 440, reliability: 93, reduction: 60 },
  { name: 'Drone-Centric', accuracy: 7, response: 116, capex: 8000, opex: 500, reliability: 90, reduction: 40 },
  { name: 'Sentry (DAS + Drone)', accuracy: 94, response: 10, capex: 2200, opex: 170, reliability: 99, reduction: 84 }
];

// Helper function to determine if a value is the best in its category
const isBestValue = (value: number, category: string, solutions: any[]) => {
  const values = solutions.map(s => s[category]);
  if (category === 'response' || category === 'capex' || category === 'opex') {
    // For these categories, lower is better
    return value === Math.min(...values);
  }
  // For other categories (accuracy, reliability, reduction), higher is better
  return value === Math.max(...values);
};

export function ComparisonSection() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto">
          <div className="text-center mb-16">
            <Trophy className="w-12 h-12 text-blue-600 mx-auto mb-4" />
            <h2 className="text-3xl font-bold mb-4">Industry-Leading Performance</h2>
            <p className="text-xl text-gray-600">
              Comparative evaluation shows Sentry outperforming traditional solutions across all key metrics
            </p>
          </div>

          {/* Key Metrics Grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6 mb-16">
            {metrics.map((metric) => {
              const Icon = metric.icon;
              return (
                <div key={metric.label} className="bg-white p-6 rounded-xl shadow-sm text-center">
                  <Icon className="w-8 h-8 text-blue-600 mx-auto mb-4" />
                  <h3 className="text-lg font-semibold mb-2">{metric.label}</h3>
                  <p className="text-3xl font-bold text-blue-600 mb-2">{metric.value}</p>
                  <p className="text-sm text-gray-600">{metric.description}</p>
                </div>
              );
            })}
          </div>

          {/* Comparison Table */}
          <div className="bg-white rounded-xl shadow-sm overflow-hidden">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-50">
                    <th className="px-6 py-4 text-left text-sm font-semibold text-gray-900">Solution</th>
                    <th className="px-6 py-4 text-center text-sm font-semibold text-gray-900">Detection Accuracy (%)</th>
                    <th className="px-6 py-4 text-center text-sm font-semibold text-gray-900">Response Time (min)</th>
                    <th className="px-6 py-4 text-center text-sm font-semibold text-gray-900">CAPEX (£k)</th>
                    <th className="px-6 py-4 text-center text-sm font-semibold text-gray-900">OPEX/year (£k)</th>
                    <th className="px-6 py-4 text-center text-sm font-semibold text-gray-900">Reliability (%)</th>
                    <th className="px-6 py-4 text-center text-sm font-semibold text-gray-900">Disruption Reduction (%)</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {solutions.map((solution) => (
                    <tr 
                      key={solution.name}
                      className={solution.name === 'Sentry (DAS + Drone)' ? 'bg-blue-50' : ''}
                    >
                      <td className="px-6 py-4 text-sm font-medium text-gray-900">{solution.name}</td>
                      <td className={`px-6 py-4 text-sm text-center ${
                        isBestValue(solution.accuracy, 'accuracy', solutions) ? 'font-bold text-blue-600' : 'text-gray-600'
                      }`}>{solution.accuracy}</td>
                      <td className={`px-6 py-4 text-sm text-center ${
                        isBestValue(solution.response, 'response', solutions) ? 'font-bold text-blue-600' : 'text-gray-600'
                      }`}>{solution.response}</td>
                      <td className={`px-6 py-4 text-sm text-center ${
                        isBestValue(solution.capex, 'capex', solutions) ? 'font-bold text-blue-600' : 'text-gray-600'
                      }`}>{solution.capex}</td>
                      <td className={`px-6 py-4 text-sm text-center ${
                        isBestValue(solution.opex, 'opex', solutions) ? 'font-bold text-blue-600' : 'text-gray-600'
                      }`}>{solution.opex}</td>
                      <td className={`px-6 py-4 text-sm text-center ${
                        isBestValue(solution.reliability, 'reliability', solutions) ? 'font-bold text-blue-600' : 'text-gray-600'
                      }`}>{solution.reliability}</td>
                      <td className={`px-6 py-4 text-sm text-center ${
                        isBestValue(solution.reduction, 'reduction', solutions) ? 'font-bold text-blue-600' : 'text-gray-600'
                      }`}>{solution.reduction}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <p className="text-sm text-gray-500 mt-4 text-center">
            *Based on comparative evaluation over 100km of infrastructure
          </p>
        </div>
      </div>
    </section>
  );
}