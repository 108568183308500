import React from 'react';
import { Bus } from 'lucide-react';
import { Link } from '../../layout/Link';
import { AnimatedBackground } from '../../common/backgrounds/AnimatedBackground';
import { SlideIn } from '../../common/animations/SlideIn';
import { FadeIn } from '../../common/animations/FadeIn';

export function BusReformHero() {
  return (
    <div className="relative min-h-[80vh] pt-24">
      <AnimatedBackground 
        videoUrl="https://cdn.coverr.co/videos/coverr-busy-bus-station-2741/preview"
        fallbackImageUrl="https://images.unsplash.com/photo-1570125909232-eb263c188f7e?auto=format&fit=crop&q=80"
        overlayOpacity={0.85}
      />
      
      <div className="relative container mx-auto px-4 py-20">
        <div className="max-w-3xl">
          <SlideIn direction="down" delay={200}>
            <div className="flex items-center space-x-4 mb-6">
              <Bus className="w-12 h-12 text-white" />
              <h1 className="text-4xl sm:text-5xl font-bold text-white">
                Bus Reform
              </h1>
            </div>
          </SlideIn>
          
          <FadeIn delay={600}>
            <p className="text-3xl font-bold text-white mb-6">
              Transforming Public Transport Systems
            </p>
            <p className="text-xl text-gray-300 mb-8">
              Delivering scalable, future-ready solutions for bus franchising and transport 
              innovation through advanced technology integration and operational excellence.
            </p>
          </FadeIn>
          
          <SlideIn direction="up" delay={800}>
            <div className="flex flex-wrap gap-4">
              <Link
                href="/contact"
                className="inline-flex items-center px-6 py-3 rounded-lg bg-blue-600 hover:bg-blue-700 transition-colors hover-lift"
              >
                Learn More About Our Solutions
              </Link>
            </div>
          </SlideIn>
        </div>
      </div>
    </div>
  );
}