import React from 'react';
import { MapPin, Phone, Mail, Linkedin } from 'lucide-react';

export function ContactSection() {
  return (
    <section className="bg-white rounded-xl shadow-sm p-8">
      <h2 className="text-2xl font-bold mb-6">Contact</h2>
      
      <div className="space-y-4">
        <div className="flex items-start space-x-3">
          <MapPin className="w-5 h-5 text-blue-600 flex-shrink-0 mt-1" />
          <div>
            <p className="text-gray-600">Derby, United Kingdom</p>
          </div>
        </div>

        <div className="flex items-start space-x-3">
          <Phone className="w-5 h-5 text-blue-600 flex-shrink-0 mt-1" />
          <div>
            <a href="tel:+447719761487" className="text-blue-600 hover:text-blue-700">
              +44 7719 761487
            </a>
          </div>
        </div>

        <div className="flex items-start space-x-3">
          <Mail className="w-5 h-5 text-blue-600 flex-shrink-0 mt-1" />
          <div>
            <a href="mailto:chinedu@proaptus.co.uk" className="text-blue-600 hover:text-blue-700">
              chinedu@proaptus.co.uk
            </a>
          </div>
        </div>

        <div className="flex items-start space-x-3">
          <Linkedin className="w-5 h-5 text-blue-600 flex-shrink-0 mt-1" />
          <div>
            <a 
              href="https://www.linkedin.com/in/proaptus/"
              className="text-blue-600 hover:text-blue-700"
              target="_blank"
              rel="noopener noreferrer"
            >
              linkedin.com/in/proaptus
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}