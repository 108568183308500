import React from 'react';
import { Users, Code, Layout, Settings } from 'lucide-react';

const capabilities = [
  {
    icon: Users,
    title: 'Programme Leadership',
    description: 'Driving multi-stakeholder initiatives with strategic alignment and risk management.',
  },
  {
    icon: Code,
    title: 'Technology Integration',
    description: 'Implementing modular, scalable systems for KPI tracking, real-time performance insights, and data integration.',
  },
  {
    icon: Layout,
    title: 'Architectural Excellence',
    description: 'Designing secure, ISO-compliant architectures that adapt to evolving franchising policies.',
  },
  {
    icon: Settings,
    title: 'Operational Transformation',
    description: 'Enhancing operator onboarding, compliance monitoring, and passenger experiences through technology.',
  },
];

export function KeyCapabilities() {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-16">Key Capabilities</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {capabilities.map((capability) => {
            const Icon = capability.icon;
            return (
              <div key={capability.title} className="text-center">
                <div className="inline-block p-4 bg-blue-50 rounded-full mb-6">
                  <Icon className="w-8 h-8 text-blue-600" />
                </div>
                <h3 className="text-xl font-semibold mb-4">{capability.title}</h3>
                <p className="text-gray-600 leading-relaxed">{capability.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}