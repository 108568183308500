import React from 'react';
import { Video } from '../../../common/video/Video';
import { Activity, Shield, LineChart } from 'lucide-react';

const features = [
  {
    icon: Activity,
    title: 'Real-Time Analysis',
    description: 'Continuous monitoring of infrastructure conditions and traffic patterns'
  },
  {
    icon: Shield,
    title: 'Predictive Maintenance',
    description: 'Early detection of potential issues before they impact operations'
  },
  {
    icon: LineChart,
    title: 'Performance Insights',
    description: 'Advanced analytics for infrastructure optimization and planning'
  }
];

export function InfrastructureMonitoringSection() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-3xl font-bold mb-6">Infrastructure Monitoring</h2>
              <p className="text-xl text-gray-600 mb-8">
                Our advanced monitoring system combines real-time data analysis with 
                predictive maintenance to ensure optimal infrastructure performance.
              </p>
              
              <div className="space-y-6">
                {features.map((feature) => {
                  const Icon = feature.icon;
                  return (
                    <div key={feature.title} className="flex items-start space-x-4">
                      <div className="p-2 bg-blue-50 rounded-lg">
                        <Icon className="w-6 h-6 text-blue-600" />
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
                        <p className="text-gray-600">{feature.description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            
            <div className="rounded-xl overflow-hidden shadow-lg">
              <Video
                src="https://firebasestorage.googleapis.com/v0/b/proaptus-website.firebasestorage.app/o/2675508-hd_1920_1080_24fps.mp4?alt=media&token=2bbd0bca-3fe6-4825-8d88-87b1ca0eecb8"
                fallbackImage="https://images.unsplash.com/photo-1465447142348-e9952c393450?auto=format&fit=crop&q=80"
                className="w-full aspect-video object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}