import React from 'react';
import { TechGrid } from './TechGrid';
import { SectionTitle } from './SectionTitle';
import { Link } from '../../layout/Link';
import { ArrowRight } from 'lucide-react';

export function TechnologyEcosystem() {
  return (
    <section className="py-20 border-t border-b border-gray-200 bg-gray-50">
      <div className="container mx-auto px-4">
        <SectionTitle />
        <TechGrid />
        
        <div className="text-center mt-12">
          <Link 
            href="/about#technology"
            className="inline-flex items-center text-blue-600 hover:text-blue-700"
          >
            Learn more about our technology ecosystem
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
        </div>
      </div>
    </section>
  );
}