import React from 'react';
import { Container } from '../../common/layout/Container';
import { FooterLinks } from './FooterLinks';
import { FooterContact } from './FooterContact';
import { FooterCopyright } from './FooterCopyright';

export function Footer() {
  return (
    <footer className="bg-gray-900 text-gray-300">
      <Container className="py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <FooterLinks />
          <FooterContact />
        </div>
        <FooterCopyright />
      </Container>
    </footer>
  );
}