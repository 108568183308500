import React from 'react';
import { Award } from 'lucide-react';

const achievements = [
  'Led the £80M Bee Network bus reform, implementing key technology services and compliance monitoring.',
  'Developed Sentry for rail infrastructure monitoring, combining DAS technology with drone-based verification.',
  'Created Novae.Travel to improve accessibility in public transport with AI-powered support.',
  'Secured Innovate UK funding for advanced infrastructure monitoring research.',
];

export function Achievements() {
  return (
    <div>
      <h3 className="font-semibold mb-3">Key Achievements</h3>
      <ul className="space-y-2">
        {achievements.map((achievement, index) => (
          <li key={index} className="flex items-start space-x-2">
            <Award className="w-5 h-5 text-blue-600 flex-shrink-0 mt-1" />
            <span className="text-gray-600">{achievement}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}