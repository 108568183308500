import React from 'react';
import { Plane } from 'lucide-react';
import { Link } from '../../layout/Link';
import { AnimatedBackground } from '../../common/backgrounds/AnimatedBackground';
import { SlideIn } from '../../common/animations/SlideIn';
import { FadeIn } from '../../common/animations/FadeIn';

export function NovaHero() {
  return (
    <div className="relative min-h-[80vh] pt-24">
      {/* Animated Background */}
      <AnimatedBackground 
        videoUrl="https://cdn.coverr.co/videos/coverr-an-aerial-view-of-an-airport-5385/preview"
        fallbackImageUrl="https://images.unsplash.com/photo-1488085061387-422e29b40080?auto=format&fit=crop&q=80"
        overlayOpacity={0.85}
      />
      
      {/* Content */}
      <div className="relative container mx-auto px-4 py-20">
        <div className="max-w-3xl">
          <SlideIn direction="down" delay={200}>
            <div className="flex items-center space-x-4 mb-6">
              <Plane className="w-12 h-12 text-white" />
              <h1 className="text-4xl sm:text-5xl font-bold text-white">
                Novae.Travel
              </h1>
            </div>
          </SlideIn>
          
          <FadeIn delay={600}>
            <p className="text-3xl font-bold text-white mb-6">
              Empowering Accessible, Real-Time Travel for All
            </p>
            <p className="text-xl text-gray-300 mb-8">
              Transform your travel experience with AI-powered personalized itineraries, 
              real-time updates, and accessibility features that ensure every journey is 
              inclusive, stress-free, and tailored to your needs.
            </p>
          </FadeIn>
          
          <SlideIn direction="up" delay={800}>
            <div className="flex flex-wrap gap-4">
              <Link
                href="https://chat.novae.travel/"
                className="inline-flex items-center px-6 py-3 rounded-lg bg-blue-600 hover:bg-blue-700 transition-colors hover-lift"
                target="_blank"
                rel="noopener noreferrer"
              >
                Try Novae.Travel for Free
              </Link>
              <Link
                href="/contact?demo=novae"
                className="inline-flex items-center px-6 py-3 rounded-lg bg-white/10 hover:bg-white/20 transition-colors hover-lift"
              >
                Learn More
              </Link>
            </div>
          </SlideIn>
        </div>
      </div>
    </div>
  );
}