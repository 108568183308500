import React from 'react';
import { MetaTags } from '../../../components/common/MetaTags';
import { ArticleLayout } from '../../../components/news/article/ArticleLayout';

const articleData = {
  id: 'proaptus-innovators-2024',
  title: 'Proaptus Wins the New Innovators in Communication Networks 2024 Competition and Showcases Innovation in Japan',
  date: '2024-12-04',
  author: 'Proaptus Team',
  category: 'ai',
  excerpt: 'Discover how Proaptus, winner of the New Innovators in Communication Networks 2024 competition, is accelerating AI-driven telecom innovation in Japan.',
  imageUrl: 'https://images.unsplash.com/photo-1536098561742-ca998e48cbcc?auto=format&fit=crop&q=80',
  content: `
    <p>
      Proaptus has been recognised as a winner of the <strong>New Innovators in Communication Networks 2024</strong> competition.
      This funding supports the development of the <em>Novae Lighthouse</em> platform, an AI-powered fibre network monitoring 
      solution designed to transform telecommunications with advanced anomaly detection technology.
    </p>

    <h2>Representing UK Innovation in Japan</h2>
    <p>
      As part of Innovate UK's Future Telecoms Global Business Innovation Programme (GBIP),
      Proaptus joined a prestigious delegation of UK innovators in Japan. 
      This initiative facilitated collaboration with leading Japanese government agencies, 
      technology firms, and telecommunications companies, underscoring Proaptus' commitment to global innovation.
    </p>

    <h2>Programme Highlights</h2>
    <ul>
      <li>
        <strong>British Embassy Tokyo Briefing:</strong> Engaged with organisations such as NICT, NEDO, JEITA, Fujitsu, 
        and Hitachi to discuss the future of telecommunications, including autonomous networks and non-terrestrial networks.
      </li>
      <li>
        <strong>NTT Musashino R&D Centre Visit:</strong> Showcased the Novae Lighthouse platform to one of the largest global telecom operators 
        while exploring cutting-edge developments in photonic gateways and AI-enabled telecom solutions.
      </li>
      <li>
        <strong>Pitch Session at CIC Tokyo Venture Café:</strong> Presented Novae Lighthouse to industry experts, investors, and technology leaders, 
        creating opportunities for collaboration and investment in AI-driven telecommunications innovation.
      </li>
    </ul>

    <h2>Project Progress</h2>
    <p>
      Since its launch in September, <em>Novae Lighthouse</em> has made significant progress:
    </p>
    <ul>
      <li>
        Demonstrated the feasibility of autonomous detection and response for critical events, 
        including earthquakes, flooding, and security breaches.
      </li>
      <li>
        Progressed to working prototype development, with live trials planned for early 2025.
      </li>
    </ul>

    <h2>Acknowledgements and Next Steps</h2>
    <p>
      Proaptus' participation in the GBIP Japan programme was made possible by Innovate UK and funding 
      from the Department for Science, Innovation and Technology (DSIT). 
      We also acknowledge the contributions of our partner, Silixa, 
      whose expertise has been instrumental in advancing the project.
    </p>
    <p>
      "Our success in the New Innovators competition and participation in the GBIP programme reflect the impact of 
      the Novae Lighthouse platform," said <strong>Chinedu Achara, Founder of Proaptus</strong>. 
      "We look forward to driving innovation in fibre network monitoring and delivering transformative solutions 
      to global markets."
    </p>
    <p>
      For more information, visit{" "}
      <a 
        href="https://proaptus.co.uk" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        Proaptus
      </a>{" "}
      and learn more about <em>Novae Lighthouse</em>.
    </p>
  `
};

export default function ProaptusInnovators2024() {
  return (
    <>
      <MetaTags
        title={articleData.title}
        description={articleData.excerpt}
        ogImage={articleData.imageUrl}
      />
      <ArticleLayout 
        article={{
          ...articleData,
          publicationInfo: (
            <p className="text-gray-600 text-sm mb-6">
              Posted by Proaptus Team · December 4, 2024
            </p>
          )
        }}
      />
    </>
  );
}