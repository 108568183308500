import React from 'react';
import { Target, Compass } from 'lucide-react';

export function VisionMission() {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <div className="bg-gradient-to-br from-blue-50 to-white p-8 rounded-xl">
            <div className="flex items-center mb-6">
              <div className="p-3 bg-blue-100 rounded-lg mr-4">
                <Target className="w-6 h-6 text-blue-600" />
              </div>
              <h2 className="text-2xl font-bold">Our Vision</h2>
            </div>
            <p className="text-gray-600 leading-relaxed">
              To lead the global transformation of critical infrastructure and transportation 
              systems through cutting-edge AI and fibre-optic sensing, creating smarter, 
              safer, and more inclusive environments for all.
            </p>
          </div>

          <div className="bg-gradient-to-br from-blue-50 to-white p-8 rounded-xl">
            <div className="flex items-center mb-6">
              <div className="p-3 bg-blue-100 rounded-lg mr-4">
                <Compass className="w-6 h-6 text-blue-600" />
              </div>
              <h2 className="text-2xl font-bold">Our Mission</h2>
            </div>
            <p className="text-gray-600 leading-relaxed">
              To revolutionize infrastructure and transportation through innovative AI solutions, 
              making systems smarter, more sustainable, and more efficient for everyone.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}