import React, { useEffect, useRef } from 'react';
import { MetaTags } from '../components/common/MetaTags';
import { AboutHero } from '../components/about/AboutHero';
import { VisionMission } from '../components/about/VisionMission';
import { Story } from '../components/about/Story';
import { CoreValues } from '../components/about/CoreValues';
import { Expertise } from '../components/about/Expertise';
import { TechnologyEcosystem } from '../components/about/TechnologyEcosystem';
import { Achievements } from '../components/about/Achievements';
import { Future } from '../components/about/Future';
import { TeamSection } from '../components/about/team/TeamSection';
import { TrackRecord } from '../components/about/TrackRecord';

interface AboutProps {
  initialSection?: string;
}

export function About({ initialSection = 'about' }: AboutProps) {
  const [section, setSection] = React.useState(initialSection);
  const technologyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.slice(1);
      setSection(hash || 'about');
      
      // Smooth scroll to the section
      if (hash) {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    window.addEventListener('hashchange', handleHashChange);
    
    // Handle initial hash
    if (window.location.hash) {
      handleHashChange();
    }

    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  return (
    <>
      <MetaTags
        title="About Proaptus: Our Mission & Vision"
        description="Learn about Proaptus' journey in transforming infrastructure through AI innovation and our commitment to creating sustainable solutions."
      />
      
      <AboutHero />
      
      <nav className="sticky top-16 bg-white border-b z-40">
        <div className="container mx-auto px-4">
          <div className="flex space-x-8 overflow-x-auto">
            {[
              { id: 'about', label: 'About Us' },
              { id: 'technology', label: 'Technology' },
              { id: 'team', label: 'Our Team' },
              { id: 'track-record', label: 'Track Record' }
            ].map(({ id, label }) => (
              <button
                key={id}
                onClick={() => {
                  setSection(id);
                  window.history.pushState(null, '', `#${id}`);
                  document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
                }}
                className={`py-4 px-2 border-b-2 whitespace-nowrap ${
                  section === id ? 'border-blue-600 text-blue-600' : 'border-transparent'
                }`}
              >
                {label}
              </button>
            ))}
          </div>
        </div>
      </nav>

      <div id="about" className={section === 'about' ? 'block' : 'hidden'}>
        <VisionMission />
        <Story />
        <CoreValues />
        <Expertise />
      </div>

      <div id="technology" className={section === 'technology' ? 'block' : 'hidden'}>
        <TechnologyEcosystem />
      </div>

      <div id="team" className={section === 'team' ? 'block' : 'hidden'}>
        <TeamSection />
      </div>
      
      <div id="track-record" className={section === 'track-record' ? 'block' : 'hidden'}>
        <TrackRecord />
      </div>
    </>
  );
}