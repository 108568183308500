import React from 'react';
import { Video } from '../../../common/video/Video';

export function SmartJourneySection() {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
            <div>
              <h2 className="text-3xl font-bold mb-6">Smart Journey Planning</h2>
              <p className="text-xl text-gray-600 mb-8">
                Experience intelligent travel planning powered by AI, delivering personalized 
                recommendations and real-time updates to optimize your journey.
              </p>
              <ul className="space-y-4 text-gray-600">
                <li className="flex items-start">
                  <span className="w-2 h-2 mt-2 rounded-full bg-blue-600 mr-3" />
                  <span>Personalized itineraries based on your preferences</span>
                </li>
                <li className="flex items-start">
                  <span className="w-2 h-2 mt-2 rounded-full bg-blue-600 mr-3" />
                  <span>Real-time updates and dynamic adjustments</span>
                </li>
                <li className="flex items-start">
                  <span className="w-2 h-2 mt-2 rounded-full bg-blue-600 mr-3" />
                  <span>AI-powered recommendations for optimal routes</span>
                </li>
              </ul>
            </div>
            <div className="rounded-xl overflow-hidden shadow-lg">
              <Video
                src="https://firebasestorage.googleapis.com/v0/b/proaptus-website.firebasestorage.app/o/Facebook%201.mp4?alt=media&token=5093024b-2613-4a07-b3e6-1ac0f9664431"
                fallbackImage="https://images.unsplash.com/photo-1488085061387-422e29b40080?auto=format&fit=crop&q=80"
                className="w-full aspect-video object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}