import React from 'react';
import { Map, HeadphonesIcon, AlertCircle } from 'lucide-react';

const applications = [
  {
    icon: Map,
    title: 'Travel Planning',
    description: 'Integration with external APIs for personalized tour recommendations and custom itineraries, complete with budgets, directions, and step-by-step plans.',
  },
  {
    icon: HeadphonesIcon,
    title: 'Real-Time Support',
    description: 'Dynamic journey assistance with live tracking, route updates, and alternate travel options, ensuring smooth connections between transport modes.',
  },
  {
    icon: AlertCircle,
    title: 'Emergency Assistance',
    description: 'Quick access to support for missed connections, disruptions, or inaccessible routes, with immediate itinerary adjustments to resolve travel challenges.',
  },
];

export function Applications() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-16">Applications</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {applications.map((app) => {
            const Icon = app.icon;
            return (
              <div key={app.title} className="bg-white p-8 rounded-lg shadow-sm">
                <div className="flex items-center mb-4">
                  <Icon className="w-6 h-6 text-blue-600 mr-3" />
                  <h3 className="text-xl font-semibold">{app.title}</h3>
                </div>
                <p className="text-gray-600">{app.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}