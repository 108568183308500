import React from 'react';
import { Shield, AlertTriangle, ClipboardCheck, Wrench } from 'lucide-react';

const applications = [
  {
    icon: Shield,
    title: 'Security Monitoring',
    description: 'Detects unauthorized access, trespassing, and vandalism in real time, alerting operators instantly to potential threats.',
  },
  {
    icon: AlertTriangle,
    title: 'Incident Reporting & Verification',
    description: 'Flags mechanical failures or environmental hazards for immediate response and verifies incidents with drones, reducing false alarms and response times.',
  },
  {
    icon: ClipboardCheck,
    title: 'Routine Inspections',
    description: 'Automates data collection to ensure compliance with infrastructure regulations and provides comprehensive records for auditing and maintenance.',
  },
  {
    icon: Wrench,
    title: 'Proactive Maintenance',
    description: 'Predicts infrastructure failures based on historical and real-time data, reducing costly disruptions through timely repairs and proactive interventions.',
  },
];

export function Applications() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-16">Applications</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {applications.map((app) => {
            const Icon = app.icon;
            return (
              <div key={app.title} className="bg-white p-8 rounded-lg shadow-sm">
                <div className="flex items-center mb-4">
                  <Icon className="w-6 h-6 text-blue-600 mr-3" />
                  <h3 className="text-xl font-semibold">{app.title}</h3>
                </div>
                <p className="text-gray-600">{app.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}