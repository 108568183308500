import React from 'react';

interface ContentItem {
  subtitle?: string;
  text?: string;
  items: string[];
}

interface PrivacyContentProps {
  title: string;
  content: ContentItem[];
}

export function PrivacyContent({ title, content }: PrivacyContentProps) {
  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">{title}</h2>
      <div className="space-y-4">
        {content.map((section, index) => (
          <div key={index}>
            {section.subtitle && (
              <h3 className="font-semibold text-gray-800 mb-2">{section.subtitle}</h3>
            )}
            {section.text && (
              <p className="text-gray-600 mb-2">{section.text}</p>
            )}
            <ul className="list-disc list-inside text-gray-600 space-y-1">
              {section.items.map((item, itemIndex) => (
                <li key={itemIndex}>{item}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}