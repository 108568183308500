import React from 'react';
import { Linkedin, Mail, Phone } from 'lucide-react';
import { SocialLink } from './SocialLink';

export function ProfileHeader() {
  return (
    <div className="text-center">
      <div className="mb-6">
        <div className="w-32 h-32 mx-auto rounded-full overflow-hidden ring-4 ring-blue-100">
          <img
            src="/images/team/chinedu-achara.png"
            alt="Chinedu Achara"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
      
      <h1 className="text-3xl font-bold mb-2">Chinedu Achara</h1>
      <p className="text-xl text-gray-600 mb-4">Founder & CEO | Proaptus</p>
      <p className="text-lg text-gray-600 mb-6">
        Driving AI Innovation in Transportation and Infrastructure.
      </p>

      <div className="flex justify-center space-x-4">
        <SocialLink
          href="https://www.linkedin.com/in/proaptus/"
          icon={Linkedin}
          label="LinkedIn"
        />
        <SocialLink
          href="mailto:chinedu@proaptus.co.uk"
          icon={Mail}
          label="Email"
        />
        <SocialLink
          href="tel:+447719761487"
          icon={Phone}
          label="Phone"
        />
      </div>
    </div>
  );
}