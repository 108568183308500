import React from 'react';
import { SplineScene } from './SplineScene';

export function HeroBackground() {
  return (
    <>
      {/* Gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-r from-[#121212] via-[#121212]/95 to-transparent lg:w-3/5" />
      
      {/* Spline Scene */}
      <div className="absolute inset-0 lg:left-1/3">
        <div className="w-full h-full opacity-30 lg:opacity-100">
          <SplineScene />
        </div>
      </div>
    </>
  );
}