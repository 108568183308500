import React from 'react';
import { Link } from '../layout/Link';

export function Footer() {
  return (
    <footer className="text-center text-gray-600 text-sm mt-8 pt-8 border-t">
      <div className="space-y-2">
        <p>© {new Date().getFullYear()} Proaptus. All rights reserved.</p>
        <p>
          <Link 
            href="https://proaptus.co.uk/chinedu-achara" 
            className="text-blue-600 hover:text-blue-700"
          >
            proaptus.co.uk/chinedu-achara
          </Link>
        </p>
      </div>
    </footer>
  );
}