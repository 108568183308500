import React from 'react';
import { Share2, Twitter, Facebook, Linkedin } from 'lucide-react';
import { Link } from '../../layout/Link';

export function ArticleFooter() {
  const shareUrl = encodeURIComponent(window.location.href);
  const shareTitle = encodeURIComponent(document.title);

  const shareLinks = [
    {
      name: 'Twitter',
      icon: Twitter,
      url: `https://twitter.com/intent/tweet?url=${shareUrl}&text=${shareTitle}`
    },
    {
      name: 'Facebook',
      icon: Facebook,
      url: `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`
    },
    {
      name: 'LinkedIn',
      icon: Linkedin,
      url: `https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=${shareTitle}`
    }
  ];

  return (
    <footer className="mt-12 pt-8 border-t">
      <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
        <div className="flex items-center gap-2">
          <Share2 className="w-5 h-5 text-gray-600" />
          <span className="text-gray-600">Share this article:</span>
          <div className="flex gap-2">
            {shareLinks.map(({ name, icon: Icon, url }) => (
              <a
                key={name}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 text-gray-600 hover:text-blue-600 transition-colors"
                aria-label={`Share on ${name}`}
              >
                <Icon className="w-5 h-5" />
              </a>
            ))}
          </div>
        </div>

        <Link
          href="/news"
          className="text-blue-600 hover:text-blue-700 transition-colors"
        >
          ← Back to News
        </Link>
      </div>
    </footer>
  );
}