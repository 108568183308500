import React from 'react';
import { Video } from '../../../common/video/Video';
import { BarChart, Clock, Users } from 'lucide-react';

const features = [
  {
    icon: BarChart,
    title: 'Data-Driven Planning',
    description: 'Optimize routes and schedules based on real passenger demand'
  },
  {
    icon: Clock,
    title: 'Service Reliability',
    description: 'Improve on-time performance and reduce service gaps'
  },
  {
    icon: Users,
    title: 'Passenger Experience',
    description: 'Enhanced service quality and customer satisfaction'
  }
];

export function ServiceOptimizationSection() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div className="order-2 lg:order-1">
              <div className="rounded-xl overflow-hidden shadow-lg">
                <Video
                  src="https://firebasestorage.googleapis.com/v0/b/proaptus-website.firebasestorage.app/o/5962372-hd_1920_1080_30fps.mp4?alt=media&token=6d85d9ab-b9e8-460c-96dd-ddd11f1e13c9"
                  fallbackImage="https://images.unsplash.com/photo-1508720666804-4253e0d846f5?auto=format&fit=crop&q=80"
                  className="w-full aspect-video object-cover"
                />
              </div>
            </div>
            
            <div className="order-1 lg:order-2">
              <h2 className="text-3xl font-bold mb-6">Service Optimization</h2>
              <p className="text-xl text-gray-600 mb-8">
                Leverage AI-powered analytics to optimize service delivery, improve efficiency,
                and enhance the passenger experience across your bus network.
              </p>
              
              <div className="space-y-6">
                {features.map((feature) => {
                  const Icon = feature.icon;
                  return (
                    <div key={feature.title} className="flex items-start space-x-4">
                      <div className="p-2 bg-blue-50 rounded-lg">
                        <Icon className="w-6 h-6 text-blue-600" />
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
                        <p className="text-gray-600">{feature.description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}