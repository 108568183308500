import React from 'react';
import { ArrowRight } from 'lucide-react';
import { Link } from '../../layout/Link';
import { FadeIn } from '../../common/animations/FadeIn';
import { SlideIn } from '../../common/animations/SlideIn';

export function HeroContent() {
  const scrollToSolutions = (e: React.MouseEvent) => {
    e.preventDefault();
    document.getElementById('solutions')?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="pt-24 lg:pt-0">
      <SlideIn direction="down" delay={200}>
        <h1 className="text-4xl sm:text-5xl lg:text-6xl xl:text-7xl font-bold leading-tight mb-6 text-white">
          Transforming Infrastructure & Transportation With AI-Driven Solutions
        </h1>
      </SlideIn>
      
      <FadeIn delay={600}>
        <p className="text-xl lg:text-2xl text-gray-300 mb-8">
          Leveraging fibre optic sensing, agentic AI, and edge analytics for safer, smarter operations.
        </p>
      </FadeIn>
      
      <SlideIn direction="up" delay={800}>
        <div className="flex flex-wrap gap-4">
          <button
            onClick={scrollToSolutions}
            className="inline-flex items-center px-6 py-3 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors hover-lift"
          >
            Explore Our Solutions
            <ArrowRight className="ml-2 h-5 w-5" />
          </button>
          
          <Link
            href="/contact"
            className="inline-flex items-center px-6 py-3 rounded-lg bg-white/10 text-white hover:bg-white/20 transition-colors hover-lift"
          >
            Contact Us
          </Link>
        </div>
      </SlideIn>
    </div>
  );
}