import React from 'react';
import { Radio, Plane, Brain } from 'lucide-react';

const features = [
  {
    icon: Radio,
    title: 'Distributed Acoustic Sensing (DAS)',
    description: 'Leverages existing fibre optic cables as vibration and noise sensors, detecting potential intrusions, derailments, or environmental hazards in real-time across entire rail networks.',
  },
  {
    icon: Plane,
    title: 'Drone Integration',
    description: 'Provides aerial inspections for incident verification and routine monitoring, reducing the time and cost of manual track inspections while improving accuracy.',
  },
  {
    icon: Brain,
    title: 'AI-Driven Analytics',
    description: 'Identifies patterns and predicts potential failures using advanced machine learning models, optimizing rail operations and delivering actionable insights.',
  },
];

export function CoreFeatures() {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-16">Core Features</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {features.map((feature) => {
            const Icon = feature.icon;
            return (
              <div key={feature.title} className="text-center">
                <div className="inline-block p-4 bg-blue-50 rounded-full mb-6">
                  <Icon className="w-8 h-8 text-blue-600" />
                </div>
                <h3 className="text-xl font-semibold mb-4">{feature.title}</h3>
                <p className="text-gray-600 leading-relaxed">{feature.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}