export interface LogoProps {
  className?: string;
  height?: number;
  onError?: () => void;
  onLoad?: () => void;
}

export const LOGO_PATHS = {
  MAIN: '/images/Main_LOGO_RGB.svg',
  SQUARE: '/images/SQ_GREY_LOGO_RGB.svg'
} as const;