import React from 'react';
import { Train, Bus, Lightbulb, Plane } from 'lucide-react';
import { NavigationCard } from './NavigationCard';

const cards = [
  {
    title: 'Sentry Rail Monitoring',
    description: 'Advanced rail infrastructure monitoring combining DAS, drones, and AI analytics.',
    icon: Train,
    href: 'https://proaptus.co.uk/solutions/sentry',
  },
  {
    title: 'Bus Network Reform',
    description: 'Leading the £80M Bee Network technology transformation programme.',
    icon: Bus,
    href: 'https://proaptus.co.uk/solutions/bus-reform',
  },
  {
    title: 'Novae.Lighthouse',
    description: 'Real-time traffic monitoring using fibre optic sensing and AI.',
    icon: Lightbulb,
    href: 'https://proaptus.co.uk/solutions/lighthouse',
  },
  {
    title: 'Novae.Travel',
    description: 'AI-powered accessibility features and personalized travel planning.',
    icon: Plane,
    href: 'https://novae.travel/',
  },
];

export function NavigationCards() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {cards.map((card) => (
        <NavigationCard key={card.title} {...card} />
      ))}
    </div>
  );
}