import { useMemo } from 'react';
import { articles } from '../data/articles';
import type { NewsArticle } from '../types/news';

interface ArticleNavigation {
  previous: NewsArticle | null;
  next: NewsArticle | null;
}

export function useArticleNavigation(currentId: string): ArticleNavigation {
  return useMemo(() => {
    const currentIndex = articles.findIndex(article => article.id === currentId);
    
    if (currentIndex === -1) {
      return { previous: null, next: null };
    }

    return {
      previous: currentIndex > 0 ? articles[currentIndex - 1] : null,
      next: currentIndex < articles.length - 1 ? articles[currentIndex + 1] : null
    };
  }, [currentId]);
}