import React from 'react';
import { Quote } from 'lucide-react';

const testimonials = [
  {
    quote: "Proaptus took our bus reform technology from development to successful launch—guiding us through development, procurement, launch, and day-to-day operations. Their expertise in managing complex requirements and aligning diverse stakeholder priorities was instrumental in driving the program to success.",
    author: "Project Lead",
    role: "Regional Transport Authority"
  },
  {
    quote: "Novae.Travel made planning my trip so effortless. Not only did it keep me within my budget, but it also generated day-by-day itineraries with automatically drawn maps and live location updates. Thanks to its real-time directions and flexible scheduling, I explored more places than I ever expected—all hassle-free!",
    author: "Satisfied User",
    role: "Novae.Travel User"
  }
];

export function Testimonials() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">What Our Clients Say</h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 max-w-6xl mx-auto">
          {testimonials.map((testimonial, index) => (
            <div 
              key={index} 
              className="bg-white p-8 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300"
            >
              <Quote className="w-8 h-8 text-blue-600 mb-4" />
              <p className="text-gray-600 mb-6 italic leading-relaxed">
                "{testimonial.quote}"
              </p>
              <div className="border-t pt-4">
                <p className="font-semibold text-gray-900">{testimonial.author}</p>
                <p className="text-blue-600">{testimonial.role}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}