import React from 'react';
import { Plane, Shield, Zap } from 'lucide-react';

export function DroneMonitoring() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-3xl font-bold mb-6">Advanced Drone Monitoring</h2>
              <p className="text-xl text-gray-600 mb-8">
                Our state-of-the-art drones combine AI-powered visual inspection with 
                real-time data analysis, providing comprehensive infrastructure monitoring 
                and verification capabilities.
              </p>
              
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                <div className="bg-white p-6 rounded-lg shadow-sm">
                  <Shield className="w-8 h-8 text-blue-600 mb-4" />
                  <h3 className="text-lg font-semibold mb-2">Incident Verification</h3>
                  <p className="text-gray-600">
                    Rapid deployment for visual confirmation of detected anomalies
                  </p>
                </div>
                
                <div className="bg-white p-6 rounded-lg shadow-sm">
                  <Plane className="w-8 h-8 text-blue-600 mb-4" />
                  <h3 className="text-lg font-semibold mb-2">Autonomous Operation</h3>
                  <p className="text-gray-600">
                    AI-guided flight paths and automated inspection routines
                  </p>
                </div>
                
                <div className="bg-white p-6 rounded-lg shadow-sm">
                  <Zap className="w-8 h-8 text-blue-600 mb-4" />
                  <h3 className="text-lg font-semibold mb-2">Real-Time Analysis</h3>
                  <p className="text-gray-600">
                    Instant processing of visual data for immediate insights
                  </p>
                </div>
              </div>
            </div>
            
            <div className="relative">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/proaptus-website.firebasestorage.app/o/Screenshot%20(2).png?alt=media&token=d11abf5f-b61b-4470-8135-0c103fb9f84b"
                alt="Novae Drone monitoring railway infrastructure"
                className="rounded-xl shadow-lg w-full"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900/20 to-transparent rounded-xl" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}