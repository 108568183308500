import React from 'react';
import { ProfileHeader } from '../components/qr/ProfileHeader';
import { NavigationCards } from '../components/qr/NavigationCards';
import { AboutSection } from '../components/qr/AboutSection';
import { ContactSection } from '../components/qr/ContactSection';
import { QRCodeSection } from '../components/qr/QRCodeSection';
import { Footer } from '../components/qr/Footer';

export function QRLanding() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-blue-50">
      <div className="max-w-4xl mx-auto px-4">
        {/* Add top padding to prevent profile picture overlap */}
        <div className="pt-24">
          <ProfileHeader />
        </div>
        
        {/* Add spacing between sections */}
        <div className="space-y-12 mt-12">
          <NavigationCards />
          <AboutSection />
          <ContactSection />
          <QRCodeSection />
          <Footer />
        </div>
      </div>
    </div>
  );
}