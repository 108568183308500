import React from 'react';
import { Network } from 'lucide-react';

export function SectionTitle() {
  return (
    <div className="text-center mb-12">
      <Network className="w-12 h-12 text-blue-600 mx-auto mb-4" />
      <h2 className="text-3xl font-bold mb-4">
        Our Technology Ecosystem & Certifications
      </h2>
      <p className="text-xl text-gray-600 max-w-3xl mx-auto">
        Leveraging industry-leading platforms and maintaining highest standards of security and compliance
      </p>
    </div>
  );
}