import React from 'react';
import { AnimateOnScroll } from '../../common/animations/AnimateOnScroll';

interface TimelineEventProps {
  year: string;
  title: string;
  description: string;
  imageSrc: string;
}

export function TimelineEvent({ year, title, description, imageSrc }: TimelineEventProps) {
  return (
    <AnimateOnScroll
      animation="animate-slide-up"
      className="relative pl-8 pb-12 last:pb-0"
    >
      <div className="absolute left-0 top-0 h-full w-0.5 bg-blue-100">
        <div className="absolute -left-1.5 top-1.5 h-4 w-4 rounded-full border-2 border-blue-600 bg-white 
          animate-[ping_1s_ease-in-out_infinite]" />
        <div className="absolute -left-1.5 top-1.5 h-4 w-4 rounded-full border-2 border-blue-600 bg-white" />
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="md:col-span-2">
          <div className="text-sm font-semibold text-blue-600 mb-1 animate-fade-in">
            {year}
          </div>
          <h3 className="text-lg font-semibold mb-2 animate-fade-in" 
              style={{ animationDelay: '200ms' }}>
            {title}
          </h3>
          <p className="text-gray-600 animate-fade-in"
             style={{ animationDelay: '400ms' }}>
            {description}
          </p>
        </div>
        
        <div className="relative h-48 rounded-lg overflow-hidden transform transition-transform duration-500 hover:scale-105">
          <img 
            src={imageSrc} 
            alt={`${title} - ${year}`}
            className="absolute inset-0 w-full h-full object-cover transition-transform duration-500 hover:scale-110"
          />
        </div>
      </div>
    </AnimateOnScroll>
  );
}