import React from 'react';
import { Lightbulb, Leaf, Award, Users } from 'lucide-react';

const values = [
  {
    icon: Lightbulb,
    title: 'Innovation',
    description: 'Pushing boundaries with cutting-edge AI and machine learning.',
  },
  {
    icon: Leaf,
    title: 'Sustainability',
    description: 'Developing solutions that balance industrial progress with environmental responsibility.',
  },
  {
    icon: Award,
    title: 'Excellence',
    description: 'Delivering reliable, high-performance solutions that exceed expectations.',
  },
  {
    icon: Users,
    title: 'Inclusivity',
    description: 'Ensuring our technologies address the needs of diverse users and communities.',
  },
];

export function CoreValues() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-16">Core Values</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {values.map((value) => {
            const Icon = value.icon;
            return (
              <div key={value.title} className="bg-white p-6 rounded-xl shadow-sm">
                <div className="inline-block p-3 bg-blue-50 rounded-lg mb-4">
                  <Icon className="w-6 h-6 text-blue-600" />
                </div>
                <h3 className="text-xl font-semibold mb-3">{value.title}</h3>
                <p className="text-gray-600">{value.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}