import React from 'react';
import { TechLogo } from './TechLogo';

const technologies = [
  {
    category: 'Cloud Platforms',
    items: [
      { name: 'AWS', icon: 'logos:aws' },
      { name: 'Microsoft Azure', icon: 'logos:microsoft-azure' },
      { name: 'Google Cloud', icon: 'logos:google-cloud' }
    ]
  },
  {
    category: 'AI & ML',
    items: [
      { name: 'OpenAI', icon: 'simple-icons:openai' },
      { name: 'NVIDIA', icon: 'logos:nvidia' },
      { name: 'Llama', icon: 'noto:llama' },
      { name: 'TensorFlow', icon: 'logos:tensorflow' },
      { name: 'PyTorch', icon: 'logos:pytorch' }
    ]
  },
  {
    category: 'Development',
    items: [
      { name: 'Python', icon: 'logos:python' },
      { name: 'TypeScript', icon: 'logos:typescript-icon' },
      { name: 'React', icon: 'logos:react' }
    ]
  },
  {
    category: 'Security & Compliance',
    items: [
      { 
        name: 'Cyber Essentials',
        customImage: 'https://firebasestorage.googleapis.com/v0/b/proaptus-website.firebasestorage.app/o/cybersec.webp?alt=media&token=e302cd10-7e38-46ff-b83f-fe85a4559b26'
      },
      { name: 'Auth0', icon: 'logos:auth0' }
    ]
  },
  {
    category: 'Tools & Analytics',
    items: [
      { name: 'HubSpot', icon: 'logos:hubspot' },
      { name: 'Jira', icon: 'logos:jira' },
      { name: 'Power BI', icon: 'logos:microsoft-power-bi' },
      { name: 'Tableau', icon: 'logos:tableau-icon' }
    ]
  },
  {
    category: 'Innovation Partners',
    items: [
      { 
        name: 'Innovate UK', 
        customImage: 'https://firebasestorage.googleapis.com/v0/b/proaptus-website.firebasestorage.app/o/s300_UKRI_IUK-Logo_OCT2019.jpg?alt=media&token=0f4d4160-dccf-4cd7-9ab6-8ddfdbcf48de',
        className: 'w-24 h-24' // Larger size for Innovate UK logo
      }
    ]
  }
];

export function TechGrid() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
      {technologies.map((tech) => (
        <div key={tech.category} className="bg-white p-6 rounded-xl shadow-sm">
          <h3 className="text-lg font-semibold mb-4">{tech.category}</h3>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
            {tech.items.map((item) => (
              <TechLogo
                key={item.name}
                name={item.name}
                icon={item.icon}
                customImage={item.customImage}
                className={item.className}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}