import React from 'react';
import { Users } from 'lucide-react';
import { LeadershipSection } from './LeadershipSection';
import { CoreTeamSection } from './CoreTeamSection';

export function TeamSection() {
  return (
    <section className="py-20 bg-gradient-to-br from-gray-50 to-white">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-blue-100 mb-6">
            <Users className="w-8 h-8 text-blue-600" />
          </div>
          <h2 className="text-3xl font-bold mb-4">Meet Our Team</h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Our team combines decades of experience in AI, telecommunications, and 
            large-scale project management to deliver cutting-edge solutions.
          </p>
        </div>

        <div className="max-w-6xl mx-auto">
          <LeadershipSection />
          <CoreTeamSection />
        </div>
      </div>
    </section>
  );
}