import React from 'react';

interface ArticleHeaderProps {
  title: string;
  publicationInfo?: React.ReactNode;
  imageUrl?: string;
}

export function ArticleHeader({ title, publicationInfo, imageUrl }: ArticleHeaderProps) {
  return (
    <header className="mb-8">
      <h1 className="text-4xl font-bold text-gray-900 mb-4">
        {title}
      </h1>
      
      {publicationInfo}
      
      {imageUrl && (
        <div className="mt-6 aspect-w-16 aspect-h-9 rounded-xl overflow-hidden">
          <img
            src={imageUrl}
            alt={title}
            className="w-full h-full object-cover"
          />
        </div>
      )}
    </header>
  );
}