import React from 'react';
import { MetaTags } from '../../components/common/MetaTags';
import { PrivacyHeader } from './components/PrivacyHeader';
import { PrivacySection } from './components/PrivacySection';
import { PrivacyFooter } from './components/PrivacyFooter';

export function PrivacyPolicy() {
  return (
    <>
      <MetaTags
        title="Privacy Policy"
        description="Learn about how Proaptus collects, uses, and protects your personal information."
        canonicalUrl="https://www.proaptus.co.uk/legal/privacy-policy"
      />
      <div className="min-h-screen bg-gray-50 py-12 px-4">
        <div className="max-w-3xl mx-auto bg-white rounded-xl shadow-sm">
          <PrivacyHeader />
          <PrivacySection />
          <PrivacyFooter />
        </div>
      </div>
    </>
  );
}