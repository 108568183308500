import React from 'react';
import { Home } from '../pages/Home';
import { About } from '../pages/About';
import { Contact } from '../pages/Contact';
import { Sentry } from '../pages/solutions/Sentry';
import { Lighthouse } from '../pages/solutions/Lighthouse';
import { NovaTravel } from '../pages/solutions/NovaTravel';
import { BusReform } from '../pages/solutions/BusReform';
import { QRLanding } from '../pages/QRLanding';
import { NewsIndex } from '../pages/news/NewsIndex';
import { getArticleComponent } from '../pages/news/articles';
import { PrivacyPolicy, CookiePolicy, TermsAndConditions } from '../pages/legal';

export function getPage(path: string = '/', hash: string = '') {
  // Handle redirects first
  if (path === '/qr') {
    window.location.href = '/chinedu-achara';
    return null;
  }

  // Normalize path
  const normalizedPath = path === '' ? '/' : path;

  // Check for news article
  const newsArticleMatch = normalizedPath.match(/^\/news\/([a-z0-9-]+)$/);
  if (newsArticleMatch) {
    const articleId = newsArticleMatch[1];
    const ArticleComponent = getArticleComponent(articleId);
    return ArticleComponent ? <ArticleComponent /> : <NewsIndex />;
  }

  // Get component for route
  const Component = routes[normalizedPath];
  if (!Component) {
    console.warn(`Route not found: ${normalizedPath}, redirecting to home`);
    return <Home />;
  }

  // Pass hash as initialSection for About page
  return normalizedPath === '/about' ? 
    <About initialSection={hash.slice(1) || 'about'} /> : 
    <Component />;
}

// Export routes for testing and debugging
export const routes = {
  '/': Home,
  '/about': About,
  '/contact': Contact,
  '/solutions/sentry': Sentry,
  '/solutions/lighthouse': Lighthouse,
  '/solutions/novae-travel': NovaTravel,
  '/solutions/bus-reform': BusReform,
  '/chinedu-achara': QRLanding,
  '/news': NewsIndex,
  '/legal/privacy-policy': PrivacyPolicy,
  '/legal/cookie-policy': CookiePolicy,
  '/legal/terms-and-conditions': TermsAndConditions,
};