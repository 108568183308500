import React from 'react';
import { Menu, X } from 'lucide-react';
import { NavigationDesktop } from './navigation/NavigationDesktop';
import { NavigationMobile } from './navigation/NavigationMobile';
import { useNavigation } from './navigation/useNavigation';

export function Navigation() {
  const { isMenuOpen, toggleMenu, closeMenu } = useNavigation();

  return (
    <nav>
      {/* Desktop Navigation */}
      <NavigationDesktop />

      {/* Mobile Menu Button */}
      <button
        className="md:hidden p-2 hover:bg-gray-100 rounded-lg transition-colors"
        onClick={toggleMenu}
        aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
        aria-expanded={isMenuOpen}
        aria-controls="mobile-menu"
      >
        {isMenuOpen ? (
          <X className="h-6 w-6 text-gray-600" />
        ) : (
          <Menu className="h-6 w-6 text-gray-600" />
        )}
      </button>

      {/* Mobile Navigation */}
      <NavigationMobile isOpen={isMenuOpen} onClose={closeMenu} />
    </nav>
  );
}