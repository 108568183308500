import React from 'react';
import { useNews } from '../../hooks/useNews';
import { NewsCard } from './NewsCard';
import { NewsFilters } from './NewsFilters';

export function NewsList() {
  const { articles, isLoading, error } = useNews();

  if (isLoading) {
    return <div className="animate-pulse">Loading articles...</div>;
  }

  if (error) {
    return <div className="text-red-600">Error loading articles: {error}</div>;
  }

  return (
    <div className="space-y-8">
      <NewsFilters />
      <div className="grid gap-6 md:grid-cols-2">
        {articles.map((article) => (
          <NewsCard key={article.id} article={article} />
        ))}
      </div>
    </div>
  );
}