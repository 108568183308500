import AIinPublicTransportation from './AIinPublicTransportation';
import AIandAccessibility from './AIandAccessibility';
import FutureOfTravel from './FutureOfTravel';
import BeyondAIAssistants from './BeyondAIAssistants';
import ProaptusInnovators2024 from './ProaptusInnovators2024';
import TransportFranchising from './TransportFranchising';

const articleComponents = {
  'ai-public-bus': AIinPublicTransportation,
  'ai-accessibility': AIandAccessibility,
  'future-of-travel': FutureOfTravel,
  'beyond-ai-assistants': BeyondAIAssistants,
  'proaptus-innovators-2024': ProaptusInnovators2024,
  'transport-franchising': TransportFranchising,
};

export function getArticleComponent(id: string) {
  return articleComponents[id as keyof typeof articleComponents];
}

export {
  AIinPublicTransportation,
  AIandAccessibility,
  FutureOfTravel,
  BeyondAIAssistants,
  ProaptusInnovators2024,
  TransportFranchising,
};