import React, { useState } from 'react';
import Spline from '@splinetool/react-spline';
import { useSplineInteraction } from '../../../hooks/useSplineInteraction';
import { FallbackScene } from './FallbackScene';

export function SplineScene() {
  const { onLoad } = useSplineInteraction();
  const [hasError, setHasError] = useState(false);

  // Handle any errors that occur during Spline initialization
  const handleError = () => {
    setHasError(true);
    console.log('Falling back to static scene due to WebGL initialization error');
  };

  if (hasError) {
    return <FallbackScene />;
  }

  return (
    <div className="w-full h-full">
      <Spline
        scene="https://prod.spline.design/pTbfa4MVquZfYr0C/scene.splinecode"
        className="w-full h-full"
        onLoad={onLoad}
        onError={handleError}
      />
    </div>
  );
}