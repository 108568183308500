import React from 'react';

export function FooterCopyright() {
  return (
    <div className="mt-12 pt-8 border-t border-gray-800 text-center text-sm">
      <p>
        © {new Date().getFullYear()} Proaptus, All Rights Reserved | 
        Company No. 11337806 | VAT No. GB300324570
      </p>
    </div>
  );
}