import React from 'react';
import { PrivacyContent } from './PrivacyContent';

const sections = [
  {
    title: '1. Information We Collect',
    content: [
      {
        subtitle: 'Personal Data',
        items: [
          'Name and contact information',
          'Email address',
          'Company details',
          'Information provided through forms'
        ]
      },
      {
        subtitle: 'Usage Data',
        items: [
          'Browser type and version',
          'Pages visited and time spent',
          'Access times and dates',
          'Device information'
        ]
      }
    ]
  },
  {
    title: '2. How We Use Your Information',
    content: [
      {
        text: 'We use collected information for the following purposes:',
        items: [
          'To provide and maintain our services',
          'To notify you about changes to our services',
          'To provide customer support',
          'To gather analysis or valuable information to improve our services',
          'To monitor the usage of our services'
        ]
      }
    ]
  },
  {
    title: '3. Data Security',
    content: [
      {
        text: 'We implement appropriate security measures to protect your personal information, including:',
        items: [
          'Encryption of transmitted data',
          'Regular security assessments',
          'Access controls and authentication',
          'Secure data storage practices'
        ]
      }
    ]
  },
  {
    title: '4. Cookies and Tracking',
    content: [
      {
        text: 'We use cookies and similar tracking technologies to enhance your experience. You can control cookie settings through your browser preferences.',
        items: [
          'Essential cookies for site functionality',
          'Analytics cookies to improve our service',
          'Preference cookies to remember your settings'
        ]
      }
    ]
  },
  {
    title: '5. Your Rights',
    content: [
      {
        text: 'You have the following rights regarding your personal data:',
        items: [
          'Right to access your personal data',
          'Right to correct inaccurate data',
          'Right to request deletion of your data',
          'Right to restrict processing of your data',
          'Right to data portability'
        ]
      }
    ]
  }
];

export function PrivacySection() {
  return (
    <div className="p-8">
      <div className="space-y-8">
        {sections.map((section) => (
          <PrivacyContent
            key={section.title}
            title={section.title}
            content={section.content}
          />
        ))}
      </div>
    </div>
  );
}