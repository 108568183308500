import React, { useState, useRef, useEffect } from 'react';
import { Play, AlertCircle } from 'lucide-react';

interface VideoProps {
  src: string;
  fallbackImage: string;
  className?: string;
  autoPlay?: boolean;
  muted?: boolean;
  loop?: boolean;
  playsInline?: boolean;
}

export function Video({
  src,
  fallbackImage,
  className = '',
  autoPlay = true,
  muted = true,
  loop = true,
  playsInline = true
}: VideoProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [isPlaying, setIsPlaying] = useState(autoPlay);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleLoadedData = () => {
      setIsLoading(false);
      if (autoPlay) {
        video.play().catch(() => setIsPlaying(false));
      }
    };

    const handleError = () => {
      setHasError(true);
      setIsLoading(false);
    };

    video.addEventListener('loadeddata', handleLoadedData);
    video.addEventListener('error', handleError);

    return () => {
      video.removeEventListener('loadeddata', handleLoadedData);
      video.removeEventListener('error', handleError);
    };
  }, [autoPlay]);

  const handlePlayPause = () => {
    if (!videoRef.current) return;

    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play().catch(console.error);
    }
    setIsPlaying(!isPlaying);
  };

  if (hasError) {
    return (
      <div className={`relative ${className}`}>
        <img 
          src={fallbackImage} 
          alt="Video fallback"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
          <div className="text-white text-center p-4">
            <AlertCircle className="w-8 h-8 mx-auto mb-2" />
            <p>Failed to load video</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="relative">
      <video
        ref={videoRef}
        className={className}
        muted={muted}
        loop={loop}
        playsInline={playsInline}
        poster={fallbackImage}
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {isLoading && (
        <div className="absolute inset-0 bg-black/20 flex items-center justify-center">
          <div className="w-8 h-8 border-4 border-white border-t-transparent rounded-full animate-spin" />
        </div>
      )}

      <button
        onClick={handlePlayPause}
        className="absolute inset-0 w-full h-full flex items-center justify-center bg-black/0 hover:bg-black/20 transition-colors group"
        aria-label={isPlaying ? 'Pause video' : 'Play video'}
      >
        <Play 
          className={`w-12 h-12 text-white opacity-0 group-hover:opacity-100 transition-opacity ${
            isPlaying ? 'hidden' : 'block'
          }`} 
        />
      </button>
    </div>
  );
}