import React from 'react';
import { ArticleLink } from './navigation/ArticleLink';
import { NavigationSpacer } from './navigation/NavigationSpacer';
import type { NewsArticle } from '../../../types/news';

interface ArticleNavigationProps {
  previous: NewsArticle | null;
  next: NewsArticle | null;
}

export function ArticleNavigation({ previous, next }: ArticleNavigationProps) {
  if (!previous && !next) return null;

  return (
    <nav className="border-t border-gray-200 mt-8 pt-8" aria-label="Article navigation">
      <div className="grid grid-cols-2 gap-8">
        <div>
          {previous ? (
            <ArticleLink article={previous} direction="previous" />
          ) : (
            <NavigationSpacer />
          )}
        </div>
        <div>
          {next ? (
            <ArticleLink article={next} direction="next" />
          ) : (
            <NavigationSpacer />
          )}
        </div>
      </div>
    </nav>
  );
}