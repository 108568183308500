interface NavigationItem {
  name: string;
  href: string;
  submenu?: Array<{ name: string; href: string; }>;
}

export const navigationItems: NavigationItem[] = [
  { name: 'Home', href: '/' },
  { 
    name: 'Solutions', 
    href: '#',
    submenu: [
      { name: 'Sentry', href: '/solutions/sentry' },
      { name: 'Lighthouse', href: '/solutions/lighthouse' },
      { name: 'Novae.Travel', href: '/solutions/novae-travel' },
      { name: 'Bus Reform', href: '/solutions/bus-reform' },
    ]
  },
  { name: 'About', href: '/about' },
  { name: 'News', href: '/news' },
  { name: 'Contact', href: '/contact' }
];