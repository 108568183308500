import React from 'react';
import { Video } from '../../../common/video/Video';
import { Activity, Shield, LineChart } from 'lucide-react';

const features = [
  {
    icon: Activity,
    title: 'Continuous Monitoring',
    description: 'Real-time tracking of infrastructure conditions and potential anomalies'
  },
  {
    icon: Shield,
    title: 'Proactive Protection',
    description: 'Early detection and prevention of infrastructure issues'
  },
  {
    icon: LineChart,
    title: 'Performance Insights',
    description: 'Advanced analytics for infrastructure health and maintenance planning'
  }
];

export function MonitoringVideo() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div className="order-2 lg:order-1">
              <div className="rounded-xl overflow-hidden shadow-lg">
                <Video
                  src="https://firebasestorage.googleapis.com/v0/b/proaptus-website.firebasestorage.app/o/134445-759734858_medium.mp4?alt=media&token=40030015-b462-4d28-a398-a5573103b935"
                  fallbackImage="https://images.unsplash.com/photo-1474487548417-781cb71495f3?auto=format&fit=crop&q=80"
                  className="w-full aspect-video object-cover"
                />
              </div>
            </div>
            
            <div className="order-1 lg:order-2">
              <h2 className="text-3xl font-bold mb-6">Advanced Monitoring System</h2>
              <p className="text-xl text-gray-600 mb-8">
                Our integrated monitoring system combines fiber optic sensing with AI analytics 
                to provide comprehensive infrastructure protection and predictive maintenance.
              </p>
              
              <div className="space-y-6">
                {features.map((feature) => {
                  const Icon = feature.icon;
                  return (
                    <div key={feature.title} className="flex items-start space-x-4">
                      <div className="p-2 bg-blue-50 rounded-lg">
                        <Icon className="w-6 h-6 text-blue-600" />
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
                        <p className="text-gray-600">{feature.description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}