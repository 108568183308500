import React from 'react';
import { Zap, BarChart, Scale, Users } from 'lucide-react';

const benefits = [
  {
    icon: Zap,
    title: 'Improved Efficiency',
    description: 'Automated processes reduce manual workload and streamline operations.',
  },
  {
    icon: BarChart,
    title: 'Enhanced Monitoring',
    description: 'Real-time data and KPI tracking for better decision-making.',
  },
  {
    icon: Scale,
    title: 'Scalable Systems',
    description: 'Future-ready infrastructure that grows with your needs.',
  },
  {
    icon: Users,
    title: 'Better Experience',
    description: 'Improved service reliability and passenger satisfaction.',
  },
];

export function Benefits() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-16">Key Benefits</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-6xl mx-auto">
          {benefits.map((benefit) => {
            const Icon = benefit.icon;
            return (
              <div key={benefit.title} className="bg-white p-6 rounded-lg shadow-sm text-center">
                <div className="inline-block p-3 bg-blue-50 rounded-lg mb-4">
                  <Icon className="w-6 h-6 text-blue-600" />
                </div>
                <h3 className="text-lg font-semibold mb-3">{benefit.title}</h3>
                <p className="text-gray-600">{benefit.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}