import React, { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { AppLayout } from './components/layout/AppLayout';
import { LoadingScreen } from './components/common/loading/LoadingScreen';
import { ErrorBoundary } from './components/common/error/ErrorBoundary';
import { PageTransition } from './components/common/transitions/PageTransition';
import { AnimationProvider } from './contexts/AnimationContext';
import { useRouting } from './hooks/useRouting';

export default function App() {
  const { page, key } = useRouting();

  return (
    <HelmetProvider>
      <AnimationProvider>
        <AppLayout>
          <ErrorBoundary>
            <Suspense fallback={<LoadingScreen />}>
              <PageTransition key={key}>
                {page}
              </PageTransition>
            </Suspense>
          </ErrorBoundary>
        </AppLayout>
      </AnimationProvider>
    </HelmetProvider>
  );
}