import React from 'react';
import { ArrowRight } from 'lucide-react';
import { Link } from '../../layout/Link';

export function CallToAction() {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-6">
            Transform Your Public Transport Systems
          </h2>
          <p className="text-xl text-gray-600 mb-8">
            Discover how Proaptus can help you implement efficient, scalable solutions 
            for your transport network.
          </p>
          <div className="flex flex-wrap justify-center gap-4">
            <Link
              href="/contact"
              className="inline-flex items-center px-6 py-3 rounded-lg bg-blue-600 
                       text-white hover:bg-blue-700 transition-colors hover-lift"
            >
              Contact Us Today
              <ArrowRight className="ml-2 h-5 w-5" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}