import React from 'react';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import { Link } from '../../../layout/Link';
import type { NewsArticle } from '../../../../types/news';

interface ArticleLinkProps {
  article: NewsArticle;
  direction: 'previous' | 'next';
}

export function ArticleLink({ article, direction }: ArticleLinkProps) {
  const Icon = direction === 'previous' ? ArrowLeft : ArrowRight;
  const label = direction === 'previous' ? 'Previous Article' : 'Next Article';
  
  return (
    <Link
      href={`/news/${article.id}`}
      className={`group flex items-start ${direction === 'next' ? 'justify-end' : ''} text-blue-600 hover:text-blue-700`}
    >
      {direction === 'previous' && (
        <Icon className="w-5 h-5 mr-2 mt-1 transition-transform group-hover:-translate-x-1" />
      )}
      <div className={direction === 'next' ? 'text-right' : ''}>
        <div className="text-sm text-gray-500">{label}</div>
        <div className="font-medium line-clamp-2">{article.title}</div>
      </div>
      {direction === 'next' && (
        <Icon className="w-5 h-5 ml-2 mt-1 transition-transform group-hover:translate-x-1" />
      )}
    </Link>
  );
}