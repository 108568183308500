import React from 'react';
import { MetaTags } from '../../components/common/MetaTags';
import { NewsHero } from '../../components/news/NewsHero';
import { NewsList } from '../../components/news/NewsList';
import { NewsletterSignup } from '../../components/news/NewsletterSignup';
import { MaxWidthContainer } from '../../components/common/layout/MaxWidthContainer';

export function NewsIndex() {
  return (
    <>
      <MetaTags
        title="News & Updates"
        description="Stay up to date with the latest news, stories, and articles from Proaptus about AI innovation in infrastructure and transportation."
      />
      
      <NewsHero />
      
      <MaxWidthContainer className="py-12">
        <NewsList />
        <NewsletterSignup />
      </MaxWidthContainer>
    </>
  );
}