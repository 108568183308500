import React from 'react';
import { Activity, AlertTriangle, LineChart, Building2 } from 'lucide-react';

const applications = [
  {
    icon: Activity,
    title: 'Traffic Flow Management',
    description: 'Real-time detection of congestion and accidents, enabling dynamic adjustments to traffic signals for smoother flow.',
  },
  {
    icon: AlertTriangle,
    title: 'Damage Detection & Localisation',
    description: 'Identify fibre breakages, road damage, and structural hazards, pinpointing exact locations to reduce repair time and costs.',
  },
  {
    icon: LineChart,
    title: 'Predictive Maintenance',
    description: 'Forecast wear and tear using historical data modeling, reducing emergency maintenance needs and ensuring network reliability.',
  },
  {
    icon: Building2,
    title: 'Urban Planning & Smart Cities',
    description: 'Provide detailed data for long-term city planning and sustainable expansion, supporting adaptive infrastructure design.',
  },
];

export function Applications() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-16">Applications</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {applications.map((app) => {
            const Icon = app.icon;
            return (
              <div key={app.title} className="bg-white p-8 rounded-lg shadow-sm">
                <div className="flex items-center mb-4">
                  <Icon className="w-6 h-6 text-blue-600 mr-3" />
                  <h3 className="text-xl font-semibold">{app.title}</h3>
                </div>
                <p className="text-gray-600">{app.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}