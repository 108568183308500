import React from 'react';
import { ChevronDown } from 'lucide-react';
import { AnimateOnScroll } from '../common/animations/AnimateOnScroll';

const faqs = [
  {
    question: "What services does Proaptus provide?",
    answer: (
      <>
        <p className="mb-4">
          Proaptus delivers AI-driven solutions for infrastructure and transportation. Our key offerings include:
        </p>
        <ul className="list-disc list-inside mb-4 space-y-2">
          <li><strong>Sentry</strong>: Advanced rail monitoring systems for safety and operational efficiency.</li>
          <li><strong>Lighthouse</strong>: Real-time traffic flow analysis and infrastructure anomaly detection.</li>
          <li><strong>Novae.Travel</strong>: An AI-powered platform enhancing accessibility and personalized travel experiences.</li>
        </ul>
        <p>
          By integrating <strong>fibre optic sensing</strong>, <strong>AI</strong>, and <strong>edge analytics</strong>, 
          we provide innovative solutions tailored to create smarter, safer, and more sustainable operations.
        </p>
      </>
    )
  },
  {
    question: "How can I request a demo of your solutions?",
    answer: (
      <>
        <p className="mb-4">You can request a personalized demo by:</p>
        <ol className="list-decimal list-inside mb-4 space-y-2">
          <li><strong>Filling out our contact form</strong> (located above).</li>
          <li><strong>Emailing us directly</strong> at <a href="mailto:contact@proaptus.co.uk" className="text-blue-600 hover:text-blue-700">contact@proaptus.co.uk</a>.</li>
        </ol>
        <p>
          Please include details about your specific needs and which solution interests you (<strong>Sentry</strong>, <strong>Lighthouse</strong>, 
          or <strong>Novae.Travel</strong>) to help us tailor the demonstration to your requirements.
        </p>
      </>
    )
  },
  {
    question: "Do you offer custom solutions for specific infrastructure needs?",
    answer: (
      <>
        <p className="mb-4">
          Absolutely. Proaptus specializes in developing tailored solutions to meet unique client challenges.
        </p>
        <ul className="list-disc list-inside space-y-2">
          <li><strong>Customization</strong>: We adapt our existing platforms or build bespoke solutions based on your specific requirements.</li>
          <li><strong>Expert Collaboration</strong>: Our team works closely with stakeholders to ensure the final solution aligns with operational goals and technical demands.</li>
        </ul>
      </>
    )
  },
  {
    question: "Are Proaptus's solutions compliant with security and data protection standards?",
    answer: (
      <>
        <p className="mb-4">
          Yes, all our solutions adhere to the highest security and compliance standards, including:
        </p>
        <ul className="list-disc list-inside space-y-2">
          <li><strong>ISO 27001 Alignment</strong>: Ensuring robust information security management.</li>
          <li><strong>GDPR Compliance</strong>: Protecting personal data and privacy.</li>
          <li><strong>Cyber Essentials Certification</strong>: Mitigating cyber risks to safeguard your infrastructure and data.</li>
        </ul>
      </>
    )
  },
  {
    question: "Which industries can benefit from Proaptus's solutions?",
    answer: (
      <>
        <p className="mb-4">Proaptus's technologies are adaptable across multiple industries, including:</p>
        <ul className="list-disc list-inside space-y-2">
          <li><strong>Transportation</strong>: Rail systems, traffic management, and accessible travel solutions.</li>
          <li><strong>Telecommunications</strong>: Fibre optic sensing and network integrity monitoring.</li>
          <li><strong>Urban Planning</strong>: Real-time insights for smart cities and sustainable infrastructure.</li>
        </ul>
      </>
    )
  },
  {
    question: "How can I contact Proaptus for additional inquiries?",
    answer: (
      <>
        <p className="mb-4">You can reach us through:</p>
        <ul className="list-disc list-inside space-y-2">
          <li><strong>Email</strong>: <a href="mailto:contact@proaptus.co.uk" className="text-blue-600 hover:text-blue-700">contact@proaptus.co.uk</a></li>
          <li><strong>Phone</strong>: <a href="tel:+441732495967" className="text-blue-600 hover:text-blue-700">01732 495967</a></li>
          <li><strong>Contact Form</strong>: Available on our website.</li>
        </ul>
        <p className="mt-4">
          We're here to assist you with any questions or requests related to our services and solutions.
        </p>
      </>
    )
  }
];

export function FAQ() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <AnimateOnScroll animation="animate-slide-up">
          <h2 className="text-3xl font-bold text-center mb-12">Frequently Asked Questions</h2>
          
          <div className="max-w-3xl mx-auto space-y-6">
            {faqs.map((faq, index) => (
              <details
                key={index}
                className="group bg-white rounded-xl shadow-sm"
              >
                <summary className="flex justify-between items-center cursor-pointer p-6">
                  <h3 className="text-lg font-semibold pr-8">{faq.question}</h3>
                  <ChevronDown className="w-5 h-5 transition-transform group-open:rotate-180" />
                </summary>
                <div className="px-6 pb-6 prose prose-blue max-w-none">
                  {faq.answer}
                </div>
              </details>
            ))}
          </div>
        </AnimateOnScroll>
      </div>
    </section>
  );
}