import React from 'react';
import { MetaTags } from '../components/common/MetaTags';
import { SchemaMarkup } from '../components/common/SchemaMarkup';
import { Hero } from '../components/home/Hero';
import { Solutions } from '../components/home/Solutions';
import { AboutSnapshot } from '../components/home/AboutSnapshot';
import { WhyChooseUs } from '../components/home/WhyChooseUs';
import { Testimonials } from '../components/home/Testimonials';
import { TechnologyEcosystem } from '../components/home/ecosystem/TechnologyEcosystem';

const organizationSchema = {
  type: 'Organization' as const,
  name: 'Proaptus Solutions',
  url: 'https://www.proaptus.co.uk',
  logo: 'https://www.proaptus.co.uk/wp-content/uploads/2024/04/LOGO_RGB-2048x809.png',
  description: 'AI-driven solutions for infrastructure and transportation, combining fibre optic sensing and edge analytics.',
  address: {
    streetAddress: '1 Derwent Business Centre, Clarke Street',
    addressLocality: 'Derby',
    postalCode: 'DE1 2BU',
    addressCountry: 'UK'
  },
  contactPoint: {
    telephone: '+441732495967',
    email: 'contact@proaptus.co.uk',
    contactType: 'Customer Support'
  }
};

export function Home() {
  return (
    <>
      <MetaTags
        title="AI-Driven Infrastructure Solutions"
        description="Transform your infrastructure with Proaptus' innovative AI solutions, combining fibre optic sensing and edge analytics for smarter, safer operations."
      />
      <SchemaMarkup schema={organizationSchema} />
      <main className="flex-grow">
        <h1 className="sr-only">Proaptus: AI-Driven Infrastructure & Transportation Solutions</h1>
        <Hero />
        <Solutions />
        <AboutSnapshot />
        <WhyChooseUs />
        <Testimonials />
        <TechnologyEcosystem />
      </main>
    </>
  );
}