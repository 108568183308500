import React, { useEffect, useRef } from 'react';

interface QRCodeProps {
  url: string;
  size?: number;
}

export function QRCode({ url, size = 200 }: QRCodeProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const generateQR = async () => {
      const QRCode = (await import('qrcode')).default;
      if (canvasRef.current) {
        QRCode.toCanvas(canvasRef.current, url, {
          width: size,
          margin: 2,
          color: {
            dark: '#000000',
            light: '#ffffff'
          }
        });
      }
    };

    generateQR();
  }, [url, size]);

  return (
    <div className="inline-block bg-white p-4 rounded-xl shadow-sm">
      <canvas ref={canvasRef} />
    </div>
  );
}