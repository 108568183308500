import React from 'react';
import { Scale } from 'lucide-react';

export function TermsHeader() {
  return (
    <div className="p-8 border-b">
      <div className="flex items-center gap-4 mb-6">
        <Scale className="w-8 h-8 text-blue-600" />
        <h1 className="text-3xl font-bold">Terms and Conditions</h1>
      </div>
      <p className="text-gray-600">
        Last Updated: March 15, 2024
      </p>
    </div>
  );
}