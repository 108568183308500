import React, { useEffect, useRef, useState } from 'react';
import { useAnimation } from '../../../contexts/AnimationContext';

interface AnimateOnScrollProps {
  children: React.ReactNode;
  animation: string;
  threshold?: number;
  className?: string;
  delay?: number;
}

export function AnimateOnScroll({ 
  children, 
  animation, 
  threshold = 0.1,
  className = '',
  delay = 0
}: AnimateOnScrollProps) {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);
  const { isTransitioning } = useAnimation();

  useEffect(() => {
    if (isTransitioning) {
      setIsVisible(false);
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setTimeout(() => setIsVisible(true), delay);
          observer.unobserve(entry.target);
        }
      },
      { threshold }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => observer.disconnect();
  }, [threshold, delay, isTransitioning]);

  return (
    <div 
      ref={elementRef}
      className={`transition-opacity duration-500 ${isVisible ? animation : 'opacity-0'} ${className}`}
      style={{ willChange: 'opacity, transform' }}
    >
      {children}
    </div>
  );
}