import React from 'react';
import { Mail, Phone, MapPin, Linkedin } from 'lucide-react';

export function FooterContact() {
  return (
    <div>
      <h4 className="text-lg font-semibold text-white mb-4">Contact Us</h4>
      <ul className="space-y-3">
        <li className="flex items-center space-x-2">
          <Mail className="w-5 h-5" />
          <a 
            href="mailto:contact@proaptus.co.uk" 
            className="hover:text-blue-400 transition-colors"
          >
            contact@proaptus.co.uk
          </a>
        </li>
        <li className="flex items-center space-x-2">
          <Phone className="w-5 h-5" />
          <a 
            href="tel:+441732495967" 
            className="hover:text-blue-400 transition-colors"
          >
            01732 495967
          </a>
        </li>
        <li className="flex items-start space-x-2">
          <MapPin className="w-5 h-5 mt-1" />
          <address className="not-italic">
            1 Derwent Business Centre<br />
            Clarke Street<br />
            Derby, DE1 2BU
          </address>
        </li>
        <li>
          <a 
            href="https://www.linkedin.com/company/proaptus/"
            className="inline-flex items-center space-x-2 hover:text-blue-400 transition-colors"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Linkedin className="w-5 h-5" />
            <span>Follow us on LinkedIn</span>
          </a>
        </li>
      </ul>
    </div>
  );
}