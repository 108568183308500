import React, { useEffect, useRef } from 'react';
import { useGoogleMapsLoader } from '../../../hooks/useGoogleMapsLoader';
import { MapFallback } from './MapFallback';
import { MAP_STYLES, MAP_OPTIONS } from './GoogleMapConfig';

interface GoogleMapProps {
  center: { lat: number; lng: number };
  zoom?: number;
}

export function GoogleMap({ center, zoom = 15 }: GoogleMapProps) {
  const mapRef = useRef<HTMLDivElement>(null);
  const { isLoaded, loadError } = useGoogleMapsLoader();

  useEffect(() => {
    if (!isLoaded || loadError || !mapRef.current) return;

    try {
      const map = new google.maps.Map(mapRef.current, {
        center,
        zoom,
        styles: MAP_STYLES,
        ...MAP_OPTIONS
      });

      new google.maps.Marker({
        position: center,
        map,
        title: 'Proaptus Office',
        animation: google.maps.Animation.DROP
      });
    } catch (error) {
      console.error('Error initializing map:', error);
    }
  }, [center, zoom, isLoaded, loadError]);

  if (loadError) {
    return <MapFallback error />;
  }

  if (!isLoaded) {
    return <MapFallback />;
  }

  return (
    <div 
      ref={mapRef}
      className="w-full h-full rounded-lg"
      aria-label="Map showing Proaptus office location"
    />
  );
}