import React from 'react';
import { Link } from '../../Link';
import { navigationItems } from './navigationItems';

interface NavigationMobileProps {
  isOpen: boolean;
  onClose: () => void;
}

export function NavigationMobile({ isOpen, onClose }: NavigationMobileProps) {
  if (!isOpen) return null;

  return (
    <div
      id="mobile-menu"
      className="absolute top-full left-0 right-0 bg-white border-b border-gray-100 shadow-lg md:hidden"
    >
      <div className="py-4">
        {navigationItems.map((item) => (
          <div key={item.name}>
            {item.submenu ? (
              <div className="space-y-2">
                <div className="px-4 py-2 text-gray-600 font-medium">
                  {item.name}
                </div>
                {item.submenu.map((subitem) => (
                  <Link
                    key={subitem.name}
                    href={subitem.href}
                    className="block px-8 py-2 text-gray-600 hover:bg-gray-50"
                    onClick={onClose}
                  >
                    {subitem.name}
                  </Link>
                ))}
              </div>
            ) : (
              <Link
                href={item.href}
                className="block px-4 py-2 text-gray-600 hover:bg-gray-50"
                onClick={onClose}
              >
                {item.name}
              </Link>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}