import { useCallback } from 'react';
import type { Application } from '@splinetool/runtime';

export function useSplineInteraction() {
  const onLoad = useCallback((splineApp: Application) => {
    // Enable mouse tracking
    splineApp.setZoom(1);
    
    // Ensure the scene is responsive to window size
    const handleResize = () => {
      splineApp.setSize(window.innerWidth, window.innerHeight);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { onLoad };
}