import React from 'react';
import { Link } from '../Link';
import { Logo } from '../logo/Logo';
import { Navigation } from './Navigation';
import { Container } from '../../common/layout/Container';

export function Header() {
  return (
    <header className="fixed w-full bg-white/90 backdrop-blur-sm z-50 border-b border-gray-100">
      <Container>
        <div className="flex justify-between items-center h-20">
          <Link href="/" className="flex items-center">
            <Logo height={48} />
          </Link>
          <Navigation />
        </div>
      </Container>
    </header>
  );
}