import React from 'react';
import { NovaHero } from '../../components/solutions/nova/NovaHero';
import { KeyFeatures } from '../../components/solutions/nova/KeyFeatures';
import { Applications } from '../../components/solutions/nova/Applications';
import { SmartJourneySection } from '../../components/solutions/nova/video-sections/SmartJourneySection';
import { SeamlessExperienceSection } from '../../components/solutions/nova/video-sections/SeamlessExperienceSection';
import { AccessibilitySection } from '../../components/solutions/nova/video-sections/AccessibilitySection';
import { RealTimeUpdatesSection } from '../../components/solutions/nova/video-sections/RealTimeUpdatesSection';
import { TargetAudience } from '../../components/solutions/nova/TargetAudience';
import { Advantages } from '../../components/solutions/nova/Advantages';
import { Development } from '../../components/solutions/nova/Development';

export function NovaTravel() {
  return (
    <div>
      <NovaHero />
      <KeyFeatures />
      <Applications />
      <SmartJourneySection />
      <SeamlessExperienceSection />
      <AccessibilitySection />
      <RealTimeUpdatesSection />
      <TargetAudience />
      <Advantages />
      <Development />
    </div>
  );
}