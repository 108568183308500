import React from 'react';

interface StaggerChildrenProps {
  children: React.ReactNode;
  animation: string;
  className?: string;
}

export function StaggerChildren({ 
  children, 
  animation,
  className = '' 
}: StaggerChildrenProps) {
  return (
    <div className={`stagger ${className}`}>
      {React.Children.map(children, (child) => (
        <div className={animation}>
          {child}
        </div>
      ))}
    </div>
  );
}