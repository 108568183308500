import React from 'react';
import { Newspaper } from 'lucide-react';
import { MaxWidthContainer } from '../common/layout/MaxWidthContainer';

export function NewsHero() {
  return (
    <div className="bg-gradient-to-br from-gray-900 to-blue-900 text-white pt-24">
      <MaxWidthContainer className="py-16">
        <div className="max-w-3xl">
          <div className="flex items-center space-x-4 mb-6">
            <Newspaper className="w-12 h-12" />
            <h1 className="text-4xl sm:text-5xl font-bold">News & Updates</h1>
          </div>
          <p className="text-xl text-gray-300">
            Stay up to date with the latest news, stories, and articles from Proaptus.
            Learn about our innovations in AI, infrastructure, and transportation.
          </p>
        </div>
      </MaxWidthContainer>
    </div>
  );
}