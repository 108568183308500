import React from 'react';
import { SentryHero } from '../../components/solutions/sentry/SentryHero';
import { CoreFeatures } from '../../components/solutions/sentry/CoreFeatures';
import { DroneMonitoring } from '../../components/solutions/sentry/DroneMonitoring';
import { MonitoringVideo } from '../../components/solutions/sentry/video-sections/MonitoringVideo';
import { IntelligentInspection } from '../../components/solutions/sentry/inspections/IntelligentInspection';
import { ComparisonSection } from '../../components/solutions/sentry/ComparisonSection';
import { Applications } from '../../components/solutions/sentry/Applications';
import { AdaptiveEdgeSecurity } from '../../components/solutions/sentry/AdaptiveEdgeSecurity';
import { UseCases } from '../../components/solutions/sentry/UseCases';

export function Sentry() {
  return (
    <div>
      <SentryHero />
      <CoreFeatures />
      <DroneMonitoring />
      <MonitoringVideo />
      <IntelligentInspection />
      <ComparisonSection />
      <Applications />
      <AdaptiveEdgeSecurity />
      <UseCases />
    </div>
  );
}