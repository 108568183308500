import React from 'react';
import { Video } from '../../../common/video/Video';

export function AccessibilitySection() {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
            <div>
              <h2 className="text-3xl font-bold mb-6">Enhanced Accessibility</h2>
              <p className="text-xl text-gray-600 mb-8">
                Making travel accessible for everyone with intuitive features and 
                personalized support that adapts to individual needs.
              </p>
              <ul className="space-y-4 text-gray-600">
                <li className="flex items-start">
                  <span className="w-2 h-2 mt-2 rounded-full bg-blue-600 mr-3" />
                  <span>Voice-guided navigation assistance</span>
                </li>
                <li className="flex items-start">
                  <span className="w-2 h-2 mt-2 rounded-full bg-blue-600 mr-3" />
                  <span>Step-free route planning</span>
                </li>
                <li className="flex items-start">
                  <span className="w-2 h-2 mt-2 rounded-full bg-blue-600 mr-3" />
                  <span>Customizable accessibility preferences</span>
                </li>
              </ul>
            </div>
            <div className="rounded-xl overflow-hidden shadow-lg">
              <Video
                src="https://firebasestorage.googleapis.com/v0/b/proaptus-website.firebasestorage.app/o/Facebook%203.mp4?alt=media&token=bcecdaf9-2158-4f16-bb07-1a821ce9fae3"
                fallbackImage="https://images.unsplash.com/photo-1573497620053-ea5300f94f21?auto=format&fit=crop&q=80"
                className="w-full aspect-video object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}