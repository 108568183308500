import React from 'react';
import { TechCategory } from './TechCategory';
import { techData } from './techData';

export function TechCategories() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
      {techData.map((category) => (
        <TechCategory
          key={category.title}
          title={category.title}
          description={category.description}
          items={category.items}
          icon={category.icon}
        />
      ))}
    </div>
  );
}