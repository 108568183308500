import React from 'react';
import { Timeline } from './track-record/Timeline';
import { Innovations } from './track-record/Innovations';

export function TrackRecord() {
  return (
    <div className="pt-16">
      <Timeline />
      <Innovations />
    </div>
  );
}