import React from 'react';
import { Network } from 'lucide-react';

export function TechHeader() {
  return (
    <div className="text-center mb-16">
      <Network className="w-12 h-12 text-blue-600 mx-auto mb-4" />
      <h2 className="text-3xl font-bold mb-4">
        Technology Ecosystem: Driving Innovation and Excellence
      </h2>
      <p className="text-xl text-gray-600 max-w-3xl mx-auto">
        Our cutting-edge technology stack and globally recognized certifications empower us to deliver 
        innovative, secure, and scalable solutions tailored to your needs.
      </p>
    </div>
  );
}